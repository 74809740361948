import React from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import RadioButton from '../common/RadioGroup/RadioButton';

interface UnitUseWizardProps {
    updateWizardFunction : (e: React.ChangeEvent<Element>, i: number, f: string, n?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    unitUseOptions: Record<string, unknown>[];
    unitUseForAll: string;
    setUnitUseForAll: (value: string) => void;
    isSameType: boolean | undefined;
    setIsSameType: (value: boolean) => void;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void;
}

export default function UnitUseWizard(props: UnitUseWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const customOrder = ["107", "105", "108", "110", "111", "106", "103", "109", "104", "102", "101", "112"];

    const headings = [<tr key="unit-use-wizard-heading-row-1">
            <th className='small-column' key="unit-use-wizard-heading-1" rowSpan={2}>{t("unit-heading.floor-level")}</th>
            <th key="unit-use-wizard-heading-2" rowSpan={2}>{t("unit-heading.tenant-name")}</th>
            <th key="unit-use-wizard-heading-3" rowSpan={2}>{t("unit-heading.unit-number")}</th>
            <th key="unit-use-wizard-heading-4" colSpan={customOrder.length}>{t("unit-heading.unit-use") + " " + t("unit-heading.select-one")}</th>
        </tr>,
        <tr key="unit-use-wizard-heading-row-2">{// eslint-disable-next-line
            customOrder.map((code) => <th key={`unit-use-${code as string}`}>{props.unitUseOptions.find((option) => code === option["unitSubtypeCode"])!["unitSubtypeDescription"] as string}</th>)
        }</tr>]

        const buildUnitUseOptions = (index?: number): JSX.Element | JSX.Element[] => {
            const unitUseOptions: JSX.Element[] = [];
            for (let i = 0; i < customOrder.length; i++) {
                // eslint-disable-next-line
                const option = props.unitUseOptions.find((option) => customOrder[i] === option["unitSubtypeCode"])!;
                unitUseOptions.push(<RadioButton key={`unit-use-option-${option["unitSubtypeCode"] as string}` + (index === undefined ? "" : `-${index}`)} 
                    name={"unit-use-option" + (index === undefined ? "" : `-${index}`)} id={`unit-use-option-${option["unitSubtypeCode"] as string}` + (index === undefined ? "" : `-${index}`)}
                    text="" textPosition='right' value={option["unitSubtypeCode"] as string} 
                    currentValue={(index !== undefined ? ((props.tenantList[index]["unitSubtypeCode"] as string) ?? "") : props.unitUseForAll)} 
                    onChange={(e) => (index !== undefined ? props.updateWizardFunction(e, index, "unitSubtypeCode") : props.setUnitUseForAll(option["unitSubtypeCode"] as string))} />);
            }
            return unitUseOptions;
        }

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`unit-use-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell'><p>{props.tenantList[index]["tenantName"] as string}</p></td>
                    <td className='no-input-cell small-cell'><p>{props.tenantList[index]["tenantUnitNumber"] as string}</p></td>
                    {(props.tenantList[index]["leaseStatusCode"] as string) !== "1" ? (buildUnitUseOptions(index) as JSX.Element[]).map((radio, i) => <td key={`unit-use-option-${index}-${i}`} className="center-cell">{radio}</td>) 
                        : <td className='wizard-grey-out-cell' colSpan={(buildUnitUseOptions(index) as JSX.Element[]).length}></td>}
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <div className='wizard-radio-cell wizard-question'>
            <fieldset>
                <legend>{t("unit-use-wizard.unit-use-question")}</legend>
                <RadioButton name="unit-use-question" id="unit-use-question-yes" text={t("yes")} textPosition='right' value="true" currentValue={props.isSameType ?? ""} onChange={() => props.setIsSameType(true)} />
                <RadioButton name="unit-use-question" id="unit-use-question-no" text={t("no")} textPosition='right' value="false" currentValue={props.isSameType ?? ""} onChange={() => props.setIsSameType(false)} />
            </fieldset>
        </div>
        {props.isSameType === true && 
            <DataTable headings={[<tr key="unit-use-all-wizard-heading-row-1">
                    <th key="unit-use-all-wizard-heading" colSpan={customOrder.length}>{t("unit-use-wizard.all-unit-use")}</th>
                </tr>,
                <tr key="unit-use-all-wizard-heading-row-2">{// eslint-disable-next-line
                    customOrder.map((code) => <th key={`unit-use-all-${code as string}`}>{props.unitUseOptions.find((option) => code === option["unitSubtypeCode"])!["unitSubtypeDescription"] as string}</th>)
                }</tr>]} multiLineHeading={true} className='wizard-table wizard-unit-use'>
                    <tr>
                        {(buildUnitUseOptions() as JSX.Element[]).map((radio, i) => <td key={`unit-use-all-option-${i}`} className="center-cell">{radio}</td>)}
                    </tr>
            </DataTable>
        }
        {props.isSameType === false && <DataTable headings={headings} multiLineHeading={true} className='wizard-table wizard-unit-use' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>}
    </div>)
}