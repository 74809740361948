import React, { ReactElement, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { DatePickerProps as ReactDatePickerProps } from "react-datepicker";
import { parseISO } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import 'react-datepicker/dist/react-datepicker.css';
import "./DatePicker.css"
import { registerLocale } from "react-datepicker";
import { enUS, fr } from 'date-fns/locale';
import { useTranslation } from "react-i18next";
import { useReadOnly } from "../../../utils/ReadOnlyContext";
import Button from "../Button/Button";
registerLocale('en', enUS)
registerLocale('fr', fr)

interface DatePickerProps extends Omit<ReactDatePickerProps, "onChange" | "date"> {
    id: string,
    name: string,
    date?: Date | string | null | undefined,
    onChange?: (date: Date | null | undefined, name: string) => void,
    position?: "top" | "bottom" | "right" | "left" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end",
    minDate?: Date,
    maxDate?: Date,
    errorMessage?: string | string[],
    disabled?: boolean,
    hasError?: boolean
}

export default function DatePicker(props: DatePickerProps): ReactElement {
    const { i18n } = useTranslation();
    const { t } = useTranslation('common');
    const { isReadOnly } = useReadOnly()

    const name = props.name;
    const [date, setDate] = useState<Date | null | undefined>()
    const [error, setError] = useState('')

    const [currentDateLevel, setCurrentDateLevel] = useState<"year" | "month" | "date">("year");

    useEffect(() => {
        const initDate = () => {
            let newDate = undefined;
            if (props.date) {
                if (typeof props.date === 'string') {
                    if (props.date.length === 10) {
                        try {
                            const dt = parseISO(props.date)
                            if (dt instanceof Date && !isNaN(dt.getTime())) {
                                newDate = dt;
                                setError("")
                            } else {
                                newDate = date;
                                setError("Invalid Date")
                            }
                        } catch (error) {
                            newDate = date;
                            setError("Invalid Date")
                        }
                    } else if (props.date.length !== 0) {
                        newDate = date;
                        setError("Invalid Date")
                    }
                } else {
                    newDate = props.date
                }
            } else if (props.selected) {
                newDate = props.selected;
            } else if (props.value) {
                newDate = parseISO(props.value);
            }
            return newDate;
        }
        setDate(initDate())
        // eslint-disable-next-line
    }, [props.date, props.selected, props.value])

    const handleDateChangeRaw = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError('');
        const currentTarget = e.currentTarget

        if (currentTarget.name === name) {
            // input value change
            let targetValue = currentTarget.value
            if (targetValue != null) {
                targetValue = targetValue.trim()
                if (targetValue.length === 0) {
                    setDate(undefined);
                    setError("")
                    if (props.onChange) {
                        props.onChange(undefined, name)
                    }
                } else if (targetValue.length === 10) {
                    try {
                        const dt = parseISO(targetValue.trim())
                        if (dt instanceof Date && !isNaN(dt.getTime())) {
                            setDate(dt)
                            setError("")
                            if (props.onChange) {
                                props.onChange(dt, name)
                            }
                        } else {
                            setError("Invalid Date")
                        }
                    } catch (error) {
                        console.log("error", error)
                    }
                } else {
                    setError("Invalid Date")
                }
            }
        }
    }

    const changeLevel = (drillingDown = true) => {
        if (currentDateLevel === "year") {
            if (drillingDown) {
                setCurrentDateLevel("month");
            }
        } else if (currentDateLevel === "month") {
            if (drillingDown) {
                setCurrentDateLevel("date");
            } else {
                setCurrentDateLevel("year");
            }
        } else {
            if (!drillingDown) {
                setCurrentDateLevel("month");
            }
        }
    }

    const dateSelected = (date: Date | null | undefined) => {
        setError('');
        setDate(date);
        changeLevel()
        if (props.onChange) {
            props.onChange(date, name);
        }
    }

    return (
        <div className="datepicker-wrapper">
            <div id={props.id + "-container"} className={'datepicker-container'}>
                <ReactDatePicker {...props} id={props.id ? props.id : props.id + "-input"} name={name} dateFormat="yyyy-MM-dd"
                    className={`${props.className} datepicker-input ${(props.hasError && 'form-error')}`}
                    placeholderText={props.placeholderText ? props.placeholderText : t("datepicker-placeholder")}
                    selected={date === undefined ? null : date}
                    locale={i18n.language} closeOnScroll={false} showPopperArrow={false}
                    popperPlacement={props.position ?? "right-start"}
                    onChangeRaw={handleDateChangeRaw}
                    onSelect={(date) => {dateSelected(date)}}
                    onBlur={() => { if (error) dateSelected(date) }}
                    autoComplete="off"
                    onChange={() => null}
                    disabled={isReadOnly || props.disabled}
                    title={props.title}
                    onCalendarClose={()=>setCurrentDateLevel("year")}
                    shouldCloseOnSelect={currentDateLevel === "date"}
                    showYearPicker={currentDateLevel === "year"}
                    showMonthYearPicker={currentDateLevel === "month"}
                >
                    {currentDateLevel !== "year" && <Button text={t("back")} className="datepicker-back-button" onClick={(e) => {e.preventDefault();changeLevel(false);}}/>}
                </ReactDatePicker>
                <FontAwesomeIcon icon={faCalendarDays} className="datepicker-icon"
                    onClick={() => document.getElementById(name + "-input")?.focus()} />
            </div>
            {(error || props.errorMessage || props.hasError) && (
                <div id={props.id + "-error"} className="input-error-message">
                    {error === "" ? props.errorMessage : error}
                </div>
            )}
        </div>
    )
}

