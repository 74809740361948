/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
import React, { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TranslationSwapper from "../../components/common/TranslationSwapper/TranslationSwapper";
import AnchorAsButton from "../../components/common/AnchorAsButton/AnchorAsButton";
import { logout } from "../../redux/actions/auth/auth";
import './Header.css';
import { ProfileOverviewState } from "../../redux/reducers/getProfile/getProfileOverviewReducer";
import { RootState } from "../../redux/reducers/root";
import { formatRoll } from "../../utils/formatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import ReactGA from 'react-ga4';

interface HeaderProps {
  showAutoSave: boolean,
  showRollNumber?: boolean,
  isInternalUser: boolean,
  setSearchInput: Dispatch<SetStateAction<string>>
}

function Header(props: HeaderProps): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  // const [lastSaved, setLastSave] = useState<Date>(new Date());
  // const [autoSave, setAutoSave] = useState(true);
  // const { isReadOnly } = useReadOnly()
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
  const address = profileOverview.profile.profile_address
  const rollNum = localStorage.getItem('rollNumber')
  const username = localStorage.getItem("username")

  // const formattedDate = lastSaved.toLocaleDateString('en-CA', {
  //   year: "numeric",
  //   month: "numeric",
  //   day: "numeric"
  // })
  // const formattedTime = lastSaved.toLocaleTimeString('en-CA', {
  //   hour: "numeric",
  //   minute: "numeric",
  //   hour12: true,
  //   timeZoneName: "short"
  // })

  // useEffect(() => {
  //   if (autoSave) {
  //     const interval = setInterval(() => {
  //       setLastSave(new Date());
  //     }, 2 * 60 * 1000)
  //     return () => clearInterval(interval);
  //   } else {
  //     return undefined;
  //   }
  // }, [autoSave]);

  // const handleHelpCentreClick = () => {
  //   ReactGA.event({
  //     category: 'User interaction with External/Resources Links',
  //     action: 'External Link click',
  //     label: 'Help Centre page',
  //   });
  //   ReactGA.send({ hitType: "pageview", page: "#/help", title: "help" })
  //   console.log('Triggered External link event with pageview')
  //   window.open("#/help", "_blank", "noreferrer");
  // }

  const logoutUser = () => {
    dispatch(logout());
    localStorage.clear();
    window.location.href = `${process.env.REACT_APP_AMP_URL}/#/auth/home`;
  }

  const goToContactUsPage = () => {
    window.open(t("contact-link"), "_blank", "noreferrer");
    ReactGA.event({
      category: 'User interaction with External/Resources Links',
      action: 'External Link click',
      label: `Contact Us Page`,
    });
    console.log("Triggered resources link click event")
  }
  return (
    <header>
      {props.isInternalUser && <div>
        <p className="internal-user-tag">Internal User View</p>
      </div>}
      <div className="autosave-container">
        {/* {props.showAutoSave && !isReadOnly && (<>
          <div className="switch-block">
            <div className="autosave-block">Auto-save</div>
            <Switch id="autosave" onChange={(e) => setAutoSave(e.target.checked)} checked={autoSave} />
          </div>
          <div className="last-saved-text">
            {autoSave ? <i>Last saved on {formattedDate} at {formattedTime}</i> : <> </>}
          </div>
        </>)} */}
      </div>
      {window.location.href.includes("#/portal") &&
        <div className="roll-address-container">
          {!props.isInternalUser ? <>
            {(props.showRollNumber !== false) && (
              <>
                {rollNum && <p className="strong roll-address-info">{formatRoll(rollNum)}</p>}
                {address && <p className="roll-address-info">{address}</p>}
              </>
            )}
          </> :
            <>
              {username && <p className="strong roll-address-info">User: {username}</p>}
            </>
          }
        </div>
      }
      <div className="header-links">
        <div className="header-links-first-row">
          <div className="header-text">
            <AnchorAsButton onClick={() => window.open(t("accessibility-link"), "_blank", "noreferrer")}>{t("accessibility")}</AnchorAsButton>
          </div>
          <div className="lang-access-item">
            <a href={t("screen-reader-link")} target="blank" title="Opens a new window">
              <img src="/images/ea_app_icon.png" alt={t("screen-reader-link")} />
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='external-link-icon-img' />
            </a>
          </div>
          <div className="translation-container header-text"><TranslationSwapper /></div>
        </div>
        <div className="header-links-second-row">
          <div className="header-text">
            <AnchorAsButton onClick={() => {
              props.setSearchInput("")
              window.open("#/landing", "_self")
            }}>{t("home")}</AnchorAsButton>
          </div>
          <div className="nav-divider">|</div>
          <div className="header-text">
            <AnchorAsButton onClick={() => window.open(t("help-link"), "_blank", "noreferrer")}>{t("helpcentre")}</AnchorAsButton>
          </div>
          <div className="nav-divider">|</div>
          <div className="header-text">
            <AnchorAsButton onClick={() => logoutUser()}>{t("signout")}</AnchorAsButton>
          </div>
          <div className="nav-divider">|</div>
          <div className="header-text">
            <AnchorAsButton onClick={goToContactUsPage}
            >{t("contact-us")}</AnchorAsButton>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;