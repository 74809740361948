import React from 'react'
import { useTranslation } from 'react-i18next';
import { getFormErrorByName, getFormErrorMessageByName, hasError, useFormErrorContext } from '../../utils/context/FormErrorContext';
import Checkbox from '../common/Checkbox/Checkbox';

interface OperatingExpenseRowProps {
    name: string;
    tooltipKey: string;
    headingKey: string;
    checked: boolean | undefined;
    disabled?: boolean;
}



function OperatingExpenseRow(props: OperatingExpenseRowProps) {

    const { t } = useTranslation("unit")
    const { errors, removeError } = useFormErrorContext()
    const className = hasError(errors, props.name) ? "table-error" : ""

    return (
        <tr>
            <td>
                <div className='flex flex-row align-items-center' title={t(`tooltips.${props.tooltipKey}`)}>
                    <Checkbox onChange={() => removeError(getFormErrorByName(errors, props.name)?.primaryKey as string)} name={props.name} defaultChecked={props.checked} checked={props.checked} disabled={props.disabled} checkboxclassname={className} />
                    <div className='padding-left-10'>{t(`headings.${props.headingKey}`)}</div>
                </div>
                <div className='input-error-message'>{getFormErrorMessageByName(errors, props.name)}</div>
            </td>
        </tr>
    )
}

export default OperatingExpenseRow