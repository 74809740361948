import { GET_ELS_LOOKUPS } from "../../actionTypes/actionsTypes";
import { AppAction, BaseAsyncReducerState } from "../../types";

export interface GetElsLookupsState extends BaseAsyncReducerState {
    dropdowns: Record<string, unknown>;
}

// Define an initial state value for the app
const initialState: GetElsLookupsState = {
    success: false,
    loading: false,
    error: false,
    dropdowns: {}
}
  
function getElsLookupsReducer(state = initialState, action: AppAction) {

    const { payload } = action;

    switch (action.type) {
        case GET_ELS_LOOKUPS.PENDING:
            return {
                loading: true,
                error: false,
            }

        case GET_ELS_LOOKUPS.SUCCESS:
            return {
                ...state,
                success: payload.success,
                dropdowns: (payload.data as Record<string, unknown>)["dropdowns"],
                loading: false,
                error: false,
            }

        case GET_ELS_LOOKUPS.ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: true
            }

        default:
            // If the reducer doesn't care about this action type,
            // return the existing state unchanged
            return state
    }
}

export default getElsLookupsReducer;