import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback } from 'react';
import i18n from '../../i18n';

// Define the shape of the error object with only the required fields from Spectral API response
export interface FormError {
  primaryKey: string;
  ruleLevel: string;
  message: string;
  messageFr: string;
}

// Context Props
interface FormErrorContextProps {
  errors: FormError[];  // Array of form validation errors
  addError: (error: FormError) => void;
  removeError: (primaryKey: string) => void;
  clearErrors: () => void;
}

// Create the context with a default value of undefined
const FormErrorContext = createContext<FormErrorContextProps | undefined>(undefined);

// Custom hook
export const useFormErrorContext = (): FormErrorContextProps => {
  const context = useContext(FormErrorContext);
  if (!context) {
    throw new Error('useFormErrorContext must be used within a FormErrorProvider');
  }
  return context;
};

// Provider props
interface FormErrorProviderProps {
  children: ReactNode;
}

export const FormErrorProvider = (props: FormErrorProviderProps) => {
  // Initial state of the form error context
  const [errors, setErrors] = useState<FormError[]>([]);

  // Add an error to the list
  const addError = (error: FormError) => {
    setErrors((prevErrors) => [...prevErrors, error]);
  };

  // Remove an error based on the primaryKey
  const removeError = (primaryKey: string) => {
    setErrors((prevErrors) => prevErrors.filter((error) => error.primaryKey !== primaryKey));
  };

  // Clear all errors
  const clearErrors = useCallback(() => {
    setErrors([]);
  }, []);

  return (
    <FormErrorContext.Provider value={{ errors, addError, removeError, clearErrors }}>
      {props.children}
    </FormErrorContext.Provider>
  );
};


// Custom hook to parse Spectral Validation Response into FormErrorContext
export const useValidationResponse = (apiResponse: Record<string, string>[]) => {
  const { addError } = useFormErrorContext();

  useEffect(() => {
    if (apiResponse.length > 0) {
      apiResponse.forEach((error) => {
        const { primaryKey, message, messageFr, ruleLevel} = error;

        addError({ primaryKey, ruleLevel, message, messageFr});
      })
    }
  }, [apiResponse, addError]);
}


export const getFormErrorByName = (errors: FormError[], key: string) => {
  return errors.find(error => error.primaryKey.split(".")[1].toLowerCase() === key.toLowerCase())
}

export const getFormErrorMessageByName = (errors: FormError[], key: string) => {
  const error = errors.find(error => error.primaryKey.split(".")[1].toLowerCase() === key.toLowerCase())

  if(error) {
    return i18n.language === 'fr' ? error.messageFr : error.message
  }

  return undefined;
}

export const hasError = (errors: FormError[], key: string) => {
  const error = errors.find(error => error.primaryKey.split(".")[1].toLowerCase() === key.toLowerCase())

  if(error !== undefined) {
    return true
  }

  return false;
}