import React, { useCallback, useEffect, useState } from 'react';
import './Comment.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/root';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import { CommentDataClass, CommentState } from '../../../redux/reducers/getComment/getCommentReducer';
import { getComment, postComment, putComment } from '../../../redux/actions/comment/comment';
import { useTranslation } from 'react-i18next';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { useReadOnly } from '../../../utils/ReadOnlyContext';
import { setSaveResultSuccess } from '../../../redux/actions/saveData/saveData';
import { useNavigation } from '../../../utils/context/NavigationContext';
import { FormError, useFormErrorContext } from '../../../utils/context/FormErrorContext';
import ErrorMessages from '../../../components/common/ErrorMessages/ErrorMessages';

export default function Comment(): JSX.Element {
    const { t } = useTranslation('pie');
    const dispatch = useDispatch();
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
    const commentState: CommentState = useSelector((state: RootState) => state.commentReducer.comment);
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const [commentId, setCommentId] = useState(-1);
    const [comment, setComment] = useState("");
    const [internalCommentId, setInternalCommentId] = useState(-1);
    const [internalComment, setInternalComment] = useState("");
    const { isReadOnly } = useReadOnly()
    const campaignStatusCode = localStorage.getItem("campaignStatusCode")
    const [submitted, setSubmitted] = useState(false)
    const { direction } = useNavigation();
    const { errors, clearErrors, removeError } = useFormErrorContext();
    const [formErrors, setFormErrors] = useState<FormError[]>([]);
    const isInternalUser = localStorage.getItem("isInternalUser") ? true : false;

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((error) => {
                if (error.primaryKey && error.primaryKey.split('.')[0] === "comment") {
                    const localKey = error.primaryKey.split('.')[1];
                    error.primaryKey = localKey;
                    // setErrorFields(prevData => ({ ...prevData, [localKey]: error.message }));
                    setFormErrors(prevData => [...prevData, error])
                }
            })
        } else {
            setFormErrors([]);
        }
    }, [errors]);

    useEffect(() => {
        if ((!commentState.loading && !commentState.success)
            || (commentState.data && commentState.data.length > 0 && commentState.data[0].campaignId !== Number(localStorage.getItem('campaignId') as string))) {
            dispatch(getComment(Number(localStorage.getItem('campaignId') as string)));
        }
        // eslint-disable-next-line
    }, []);

    const findComment = useCallback((cId: number) => {
        let commentData: CommentDataClass | undefined = undefined;
        if (commentState.data) {
            commentData = (commentState.data).find(({ commentId }) => commentId === cId);
        }
        return commentData;
    }, [commentState.data]);

    useEffect(() => {
        if (submitted && !saveResultState.loading) {
            if (saveResultState.status === 200) {
                dispatch(getComment(Number(localStorage.getItem('campaignId') as string)));
                setFormErrors([]);
            }
        }
    }, [dispatch, saveResultState, submitted]);

    useEffect(() => {
        if (saveData.saving && !isInternalUser) {
            setSubmitted(true)
            clearErrors()
            if (commentId < 0) {
                if (findComment(commentId)) {
                    dispatch(postComment(Number(localStorage.getItem('campaignId') as string),
                        { ...(findComment(commentId)), "campaignStatusCode": campaignStatusCode }, direction === "forward" ? true : false));
                } else {
                    dispatch(setSaveResultSuccess())
                }
            } else {
                dispatch(putComment(Number(localStorage.getItem('campaignId') as string),
                    commentId, { ...(findComment(commentId)), "campaignStatusCode": campaignStatusCode }, direction === "forward" ? true : false));
            }
        }
        // eslint-disable-next-line
    }, [dispatch, saveData.saving, commentId, campaignStatusCode])

    useEffect(() => {
        if (saveData.saving && isInternalUser) {
            if (internalCommentId < 0) {
                if (findComment(internalCommentId)) {
                    dispatch(postComment(Number(localStorage.getItem('campaignId') as string),
                        { ...(findComment(internalCommentId)), "campaignStatusCode": campaignStatusCode }, false));
                }
            } else {
                dispatch(postComment(Number(localStorage.getItem('campaignId') as string),
                    { ...(findComment(internalCommentId)), "campaignStatusCode": campaignStatusCode }, false));
            }
        }
    }, [dispatch, saveData.saving, internalCommentId, findComment, campaignStatusCode, isInternalUser])

    useEffect(() => {
        if (!commentState.loading && commentState.data) {
            const commentData = commentState.data.find((comment) => comment.commentTypeCode === "EXT");
            if (commentData) {
                setCommentId(commentData.commentId ?? -1);
                setComment(commentData.commentText ?? "");
            }
            const internalCommentData = commentState.data.find((comment) => comment.commentTypeCode === "INT");
            if (internalCommentData) {
                setInternalCommentId(internalCommentData.commentId ?? -1);
                setInternalComment(internalCommentData.commentText ?? "");
            }
        } else if (!commentState.loading && !commentState.success) {
            setCommentId(-1);
            setComment("");
            setInternalCommentId(-1);
            setInternalComment("");
        }
    }, [commentState.data, commentState.loading, commentState.success]);

    const updateComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        removeError(e.target.name)
        const comment = (e.currentTarget as HTMLTextAreaElement).value
        if (comment.length <= 5000) {
            const commentData = findComment(commentId);
            if (commentData) {
                commentData.commentText = comment;
            } else {
                const newComment = {
                    commentId: commentId,
                    commentDate: new Date().toISOString(),
                    commentTypeCode: "EXT",
                    userName: "",
                    profileId: Number(localStorage.getItem('profileId') as string),
                    campaignId: Number(localStorage.getItem('campaignId') as string),
                    commentText: comment,
                    deletedFlag: false
                }
                if (commentState.data) {
                    (commentState.data).push(newComment);
                } else {
                    commentState.data = [newComment];
                }
            }
            setComment(comment);
        }
    }

    const updateInternalComment = (comment: string) => {
        if (comment.length <= 5000) {
            const commentData = findComment(internalCommentId);
            if (commentData) {
                commentData.commentText = comment;
            } else {
                const newComment = {
                    commentId: internalCommentId,
                    commentDate: new Date().toISOString(),
                    commentTypeCode: "INT",
                    userName: "",
                    profileId: Number(localStorage.getItem('profileId') as string),
                    campaignId: Number(localStorage.getItem('campaignId') as string),
                    commentText: comment,
                    deletedFlag: false
                }
                if (commentState.data) {
                    (commentState.data).push(newComment);
                } else {
                    commentState.data = [newComment];
                }
            }
            setInternalComment(comment);
        }
    }

    return (<>
        <div className='partial-width'>
            <div>
                <h3>{t("commentDescription")}</h3>
                <div className='comment-error-section'>
                    <ErrorMessages errors={formErrors}></ErrorMessages>
                </div>
                <textarea id="comments" name="comments" cols={80} rows={12} maxLength={1100} value={comment}
                    onChange={(e) => updateComment(e)}
                    className={`comment-textbox ${formErrors.length ? 'form-error' : ''}`} disabled={isInternalUser || isReadOnly} />
            </div>
            {isInternalUser && <>
                <div>
                    <h3>Internal comments</h3>
                    <textarea id="comments internal-comments" name="internal-comments" cols={80} rows={12} value={internalComment}
                        onChange={(e) => updateInternalComment((e.currentTarget as HTMLTextAreaElement).value)}
                        className='comment-textbox internal-comment' disabled={isReadOnly} maxLength={1100} />
                </div>
            </>}
        </div>
    </>);
}
