/**
 * Update: 18/04/2024 - Mariam Bawa - Refactor code to use IncomeAttributeTableFragmentGenerator to generate table content and cast data to IncomeAttributeLookupObject
*  Update: 18/04/2024 - Mariam Bawa - Added thead element in markup to meet AODA standards. 
Element is then hidden from UI using CSS
*/
import React from "react";
import IncomeAttributeLookupObject from "../../../types/lookups/IncomeAttributeLookupObject";
import IncomeAttributeTableFragmentGenerator from "../../../utils/TableGenerator/IncomeAttributeTableFragmentGenerator";

export interface RevenueTabProps {
  handleTotalIncome: (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => void,
  handleTotalLoss?: (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => void,
  handleCommentChange: (value: string, name: string) => void,
  lookups: IncomeAttributeLookupObject[],
  name: string,
  incomeAttributes?: Record<string, unknown>[],
  lookupType: string;
  errors?: Record<string, string>
}

export default function RevenueTab(props: RevenueTabProps): JSX.Element {
  const valueChangeHandler = (props.name === 'rentalLoss' && props.handleTotalLoss !== undefined) ? props.handleTotalLoss : props.handleTotalIncome;

  return (
    <>
      {
        props.lookups &&
        <table className="table-with-border pie-table accordion-content">
          <thead>
            <tr>
              <th id="attribute-label" scope="col"></th>
              <th id="attribute-value" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {
              props.lookups.map((attr, key) => {
                let incomeAttributesData: Record<string, unknown> | undefined = undefined;
                if (props.incomeAttributes) {
                  incomeAttributesData = props.incomeAttributes.find(({ code }) => code === attr.incomeAttributeCode);
                }
                if (attr.incomeAttributeCode === "7") {
                  if (props.lookupType === "bip-income-attributes") {
                    return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                      data={incomeAttributesData}
                      showSubheading={true} hasInputLabel={true} isOtherLabel={true}
                      handleCommentChange={props.handleCommentChange}
                      errors={props.errors}
                      valueChangeHandler={valueChangeHandler} isRequiredField={false} />
                  } else {
                    return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                      data={incomeAttributesData}
                      showSubheading={false} hasInputLabel={true} isPlaceHolderWithAttrDesc={true}
                      isOtherLabel={false}
                      handleCommentChange={props.handleCommentChange}
                      errors={props.errors}
                      valueChangeHandler={valueChangeHandler} isRequiredField={false} />
                  }
                } else if (attr.incomeAttributeCode === "12" && props.lookupType === "htl-income-attributes") {
                  return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                    data={incomeAttributesData}
                    showSubheading={false} hasInputLabel={false} isOtherLabel={false}
                    handleCommentChange={props.handleCommentChange}
                    errors={props.errors}
                    valueChangeHandler={valueChangeHandler} isRequiredField={true} />
                } else if (["145", "30", "20"].includes(attr.incomeAttributeCode)) {
                  if (props.lookupType === "ltc-income-attributes") {
                    // attr["incomeAttributeDescription"] = "Other Income";
                    // attr["incomeAttributeFrenchDescription"] = "Autres revenus";
                  }
                  return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                    data={incomeAttributesData}
                    showSubheading={false} hasInputLabel={true} isPlaceHolderWithAttrDesc={true}
                    isOtherLabel={false}
                    handleCommentChange={props.handleCommentChange}
                    errors={props.errors}
                    valueChangeHandler={valueChangeHandler} isRequiredField={false} />
                } else if (String(attr.incomeAttributeCode) === '159' && props.lookupType === "ltc-income-attributes") {
                  // attr["incomeAttributeDescription"] = "Reported Vacancy %";
                  // attr["incomeAttributeFrenchDescription"] = "Inoccupation déclarée en %";
                  return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                    data={incomeAttributesData}
                    showSubheading={false} hasInputLabel={false} isOtherLabel={false}
                    handleCommentChange={props.handleCommentChange}
                    errors={props.errors}
                    valueChangeHandler={valueChangeHandler} isRequiredField={false} fieldType="percent" />
                } else if (String(attr.incomeAttributeCode) === '8' || attr.incomeAttributeCode === '153') {
                  return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                    data={incomeAttributesData}
                    showSubheading={false} hasInputLabel={true} isOtherLabel={false}
                    handleCommentChange={props.handleCommentChange}
                    errors={props.errors}
                    valueChangeHandler={valueChangeHandler} isRequiredField={false} />
                } else {
                  return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                    data={incomeAttributesData}
                    showSubheading={false} hasInputLabel={false} isOtherLabel={false}
                    handleCommentChange={props.handleCommentChange}
                    errors={props.errors}
                    valueChangeHandler={valueChangeHandler} isRequiredField={false} />
                }
              }
              )
            }
          </tbody>
        </table>
      }
    </>
  );
}
