import React from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import RadioButton from '../common/RadioGroup/RadioButton';

interface LeaseTypeWizardProps {
    updateWizardFunction : (e: React.ChangeEvent<Element>, i: number, f: string, n?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    leaseTypeOptions: Record<string, unknown>[];
    leaseTypeForAll: string;
    setLeaseTypeForAll: (value: string) => void;
    isSameType: boolean | undefined;
    setIsSameType: (value: boolean) => void;
    unitSubtypeLookup: Record<string, unknown>;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void;
}

export default function LeaseTypeWizard(props: LeaseTypeWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");

    const capitalizeFirstOfEachWord = (str: string) : string => {
        const strArr = str.split(" ");
        const captializedStrArr = strArr.map((s) => {
            return (s.length > 0 ? s[0].toLocaleUpperCase() : "") + (s.length > 1 ? s.substring(1).toLocaleLowerCase() : "");
        });
        return captializedStrArr.join(" ");
    }

    const headings = [<tr key="lease-status-wizard-heading-row-1">
            <th className="small-columm" key="basic-info-wizard-heading-1" rowSpan={2}>{t("unit-heading.floor-level")}</th>
            <th key="basic-info-wizard-heading-2" rowSpan={2}>{t("unit-heading.tenant-name")}</th>
            <th key="basic-info-wizard-heading-3" rowSpan={2}>{t("unit-heading.unit-number")}</th>
            <th key="basic-info-wizard-heading-4" rowSpan={2}>{t("unit-heading.unit-use")}</th>
            <th key="basic-info-wizard-heading-5" colSpan={props.leaseTypeOptions.length}>{t("unit-heading.lease-type") + " " + t("unit-heading.select-one")}</th>
        </tr>,
        <tr key="lease-status-wizard-heading-row-2">
            {props.leaseTypeOptions.map((leaseType) => 
            leaseType["value"] as string !== "" && <th key={`lease-status-${leaseType["value"] as string}`}>{capitalizeFirstOfEachWord(leaseType["text"] as string)}</th>)}
        </tr>]

    const buildLeaseTypeOptions = (index?: number): JSX.Element | JSX.Element[] => {
        const leaseTypeOptions: JSX.Element[] = [];
        for (let i = 0; i < props.leaseTypeOptions.length; i++) {
            if (props.leaseTypeOptions[i]["value"] as string !== "") {
                leaseTypeOptions.push(<RadioButton key={`lease-type-option-${(props.leaseTypeOptions[i]["text"] as string).toLocaleLowerCase()}` + (index === undefined ? "" : `-${index}`)}
                    name={"lease-type-option" + (index === undefined ? "" : `-${index}`)} id={`lease-type-option-${(props.leaseTypeOptions[i]["text"] as string).toLocaleLowerCase()}` + (index === undefined ? "" : `-${index}`)}
                    text="" textPosition='right' value={props.leaseTypeOptions[i]["value"] as string} 
                    currentValue={(index !== undefined ? ((props.tenantList[index]["leaseTypeCode"] as string) ?? "") : props.leaseTypeForAll)} 
                    onChange={(e) => (index !== undefined ? props.updateWizardFunction(e, index, "leaseTypeCode") : props.setLeaseTypeForAll(props.leaseTypeOptions[i]["value"] as string))} />);
            }
        }
        return leaseTypeOptions;
    }

    const buildTableContent = (): JSX.Element => {
        const tableBody: JSX.Element[] = [];
        for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
            tableBody.push (<tr key={`basic-info-wizard-row-${index}`}>
                <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[index]["tenantName"] as string}</p></td>
                <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[index]["tenantUnitNumber"] as string}</p></td>
                <td className='no-input-cell'><p>{props.unitSubtypeLookup[props.tenantList[index]["unitSubtypeCode"] as string] as string}</p></td>
                {(props.tenantList[index]["leaseStatusCode"] as string) !== "1" ? (buildLeaseTypeOptions(index)as JSX.Element[]).map((radio, i) => <td key={`lease-type-option-${index}-${i}`} className="center-cell">{radio}</td>)
                    : <td className='wizard-grey-out-cell' colSpan={(buildLeaseTypeOptions(index) as JSX.Element[]).length}></td>}
            </tr>)
        }
        return (<>{tableBody}</>);
    }

    return (<div>
        <div className='wizard-radio-cell wizard-question'>
            <fieldset>
                <legend>{t("lease-type-wizard.lease-type-question")}</legend>
                <RadioButton name="lease-type-question" id="lease-type-question-yes" text={t("yes")} textPosition='right' value="true" currentValue={props.isSameType ?? ""} onChange={() => props.setIsSameType(true)} />
                <RadioButton name="lease-type-question" id="lease-type-question-no" text={t("no")} textPosition='right' value="false" currentValue={props.isSameType ?? ""} onChange={() => props.setIsSameType(false)} />
            </fieldset>
        </div>
        {props.isSameType === true && 
            <DataTable headings={[<tr key="lease-status-all-wizard-heading-row-1">
                <th key="lease-status-all-wizard-heading" colSpan={props.leaseTypeOptions.length}>{t("lease-type-wizard.all-lease-type")}</th>
            </tr>,
            <tr key="lease-status-all-wizard-heading-row-2">
                {props.leaseTypeOptions.map((leaseType) => 
                    leaseType["value"] as string !== "" && <th key={`lease-status-all-${leaseType["value"] as string}`}>{capitalizeFirstOfEachWord(leaseType["text"] as string)}</th>)}
            </tr>]} multiLineHeading={true} className='wizard-table'>
                <tr>
                    {(buildLeaseTypeOptions() as JSX.Element[]).map((radio, i) => <td key={`lease-type-all-option-${i}`} className="center-cell">{radio}</td>)}
                </tr>
            </DataTable>
        }
        {props.isSameType === false && <DataTable headings={headings} multiLineHeading={true} className='wizard-table' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>}
    </div>)
}