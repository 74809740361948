/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faLock, faLockOpen, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import Asterisk from '../../../components/common/Asterisk/Asterisk'
import Button from '../../../components/common/Button/Button'
import DataTable from '../../../components/common/DataTable/DataTable'
import Dropdown, { Option } from '../../../components/common/Dropdown/Dropdown'
import Input from '../../../components/common/Input/Input'
import Modal from '../../../components/common/Modal/Modal'
import RadioButton from '../../../components/common/RadioGroup/RadioButton'
import { deleteIncomeUnitById, deleteMultipleIncomeUnits, getApartmentTenantSummaryBySnapshotId, postIncomeUnit, putIncomeUnit, resetDeleteIncomeUnitById, resetGetTenantSummaryBySnapshotId, resetPostIncomeUnit, resetPutIncomeUnit } from '../../../redux/actions/incomeUnit/incomeUnit'
import { getElsLookups } from '../../../redux/actions/lookups/lookups'
import { setSaveResultSuccess } from '../../../redux/actions/saveData/saveData'
import { RootState } from '../../../redux/reducers/root'
import { createUnitState } from '../../../redux/reducers/getIncomeUnit/createUnitReducer'
import { updateUnitState } from '../../../redux/reducers/getIncomeUnit/updateUnitReducer'
import { GetElsLookupsState } from '../../../redux/reducers/getLookups/getElsLookupsReducer'
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer'
import { BaseAsyncReducerState } from '../../../redux/types'
import { useReadOnly } from '../../../utils/ReadOnlyContext'
import MultiresWizard from '../../../components/MultiresWizard/MultiresWizard'
import "./SuiteBreakdown.css"
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer'
import { ProfileDetailsState } from '../../../redux/reducers/getProfile/getProfileDetailsReducer'
import { putProfileDetails, resetPutProfileDetails } from '../../../redux/actions/profile/profile'
import ReactGA from 'react-ga4';
import { defaultNumberPerPage } from '../../../assets/data/pagination/pagination'

interface SuiteBreakdownProps {
    tableData?: Record<string, unknown>[]  // For testing purpose, takes in a hard coded data array
}

export default function SuiteBreakdown(props: SuiteBreakdownProps): JSX.Element {
    const { isReadOnly } = useReadOnly()
    const [unitData, setUnitData] = useState<Record<string, unknown>[]>(props.tableData ?? []);
    const [isToastVisible, setisToastVisible] = useState(false);
    const [editableUnitIndex, setEditableUnitIndex] = useState<number | null>(null);
    const [isRequestSent, setIsRequestSent] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [hasRowBeenChanged, setHasRowBeenChanged] = useState(false)
    const [modifiedIdxs, setModifiedIdxs] = useState<number[]>([])
    const { t } = useTranslation(["suite_breakdown_table", "common"]);
    const [currentPage, setCurrentPage] = useState(1);
    const [numberPerPage, setNumberPerPage] = useState(defaultNumberPerPage);
    const apartmentTenantSummaryState: BaseAsyncReducerState = useSelector((state: RootState) => state.incomeUnitReducer.getApartmentTenantSummary);
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
    const updateUnitState: updateUnitState = useSelector((state: RootState) => state.incomeUnitReducer.putIncomeUnit);
    const createUnitState: createUnitState = useSelector((state: RootState) => state.incomeUnitReducer.postIncomeUnit);
    const deleteIncomeUnitState: BaseAsyncReducerState = useSelector((state: RootState) => state.incomeUnitReducer.deleteIncomeUnit);
    const isModifiedSubmission = localStorage.getItem("isInternalUser") && localStorage.getItem("editMode");
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const profileDetails: ProfileDetailsState = useSelector((state: RootState) => state.profileReducer.getProfileDetails);
    const [currentSnapshotId, setCurrentSnapshotId] = useState(Number(localStorage.getItem("currentSnapshotId")));
    const [profileData, setProfileData] = useState({ ...profileDetails.data })
    const [styleOptions, setStyleOptions] = useState<Option[]>([]);
    const [unitSubtype3Options, setUnitSubtype3Options] = useState<Option[]>([]);
    const [tenancyTypeOptions, setTenancyTypeOptions] = useState<Option[]>([]);
    const [showGuidedModal, setShowGuidedModal] = useState(false)
    const showResidentialQuestion = ["402", "403", "409", "417", "419", "425", "426", "427", "428", "429", "430", "431", "433", "434", "435", "436", "438", "481", "483", "580"].includes(localStorage.getItem("propertyCode") as string)
    const [isResidential, setIsResidential] = useState<boolean | null>(showResidentialQuestion ? null : true)

    const dispatch = useDispatch()
    const propertyCode = localStorage.getItem("propertyCode") as string
    const isMultiresMixeduse = ["340", "341", "352", "361", "602", "470", "472", "478"].includes(propertyCode)
    const isLLE = ["308", "382"].includes(propertyCode)
    const [unitTypeSubtypeMap, setUnitTypeSubtypeMap] = useState<Record<string, Option[]>>({})

    const sortByTenantId = (array: Record<string, unknown>[]): Record<string, unknown>[] => {
        return array.sort((a, b) => {
            const tenantIdA = a.tenantId as number
            const tenantIdB = b.tenantId as number

            if (tenantIdA === undefined && tenantIdB !== undefined) {
                return -1
            }

            if (tenantIdA !== undefined && tenantIdB === undefined) {
                return 1
            }

            if (tenantIdA === undefined && tenantIdB === undefined) {
                return 0
            }

            if (tenantIdA > tenantIdB) {
                return -1
            }

            if (tenantIdA < tenantIdB) {
                return 1
            }
            return 0
        })
    }

    useEffect(() => {
        if (deleteIncomeUnitState.success) {
            dispatch(resetDeleteIncomeUnitById())
            setIsRequestSent(false)
            setEditableUnitIndex(null)
            toast.success(t("data-deleted") as string)
        }
    }, [deleteIncomeUnitState.success, dispatch, t]);


    useEffect(() => {
        if (updateUnitState.success) {
            setIsRequestSent(false)
            setEditableUnitIndex(null)
            toast.success(t("data-saved") as string)
            dispatch(resetPutIncomeUnit())
        }
    }, [updateUnitState, dispatch, t])

    useEffect(() => {
        const replaceUnitWithPostResponse = (unit: Record<string, unknown>) => {
            const dataSet = [...unitData];
            const index = modifiedIdxs[0];
            dataSet[index] = unit;
            setUnitData(dataSet);
            setModifiedIdxs([]);
        };

        if (createUnitState.success) {
            dispatch(resetPostIncomeUnit())
            setIsRequestSent(false)
            setEditableUnitIndex(null)
            const created = createUnitState.data as Record<string, unknown>
            if (created["tenantId"]) {
                replaceUnitWithPostResponse(created)
                toast.success(t("data-created") as string)
            } else {
                const dataSet: Record<string, unknown>[] = []
                unitData.forEach((data) => {
                    if (data["tenantId"]) {
                        dataSet.push(data)
                    }
                })
                setUnitData(dataSet)
                setModifiedIdxs([])
            }
        }

    }, [createUnitState, dispatch, unitData, t, modifiedIdxs])

    const submitApartment = useCallback(() => {
        dispatch(resetPutProfileDetails());
        dispatch(putProfileDetails(profileOverview.profile.profile_type_code, currentSnapshotId, profileData));
        if (!isResidential && unitData?.length) {
            dispatch(deleteMultipleIncomeUnits(currentSnapshotId, unitData))
        }
    }, [dispatch, profileOverview, currentSnapshotId, profileData, unitData, isResidential])

    useEffect(() => {
        if (saveData.saving) {
            if (!updateUnitState.loading && !createUnitState.loading && !deleteIncomeUnitState.loading) {
                dispatch(setSaveResultSuccess())
            }
        }
    }, [createUnitState.loading, deleteIncomeUnitState.loading, dispatch, saveData.saving, updateUnitState.loading])

    useEffect(() => {
        if (saveData.saving) {
            submitApartment();
        }
    }, [dispatch, saveData.saving, submitApartment])

    useEffect(() => {
        if (!isRequestSent) {
            dispatch(getApartmentTenantSummaryBySnapshotId(currentSnapshotId))
            setDataFetched(true)
            setIsRequestSent(true)
        }
    }, [currentSnapshotId, dispatch, isRequestSent])

    useEffect(() => {
        if (!apartmentTenantSummaryState.loading) {
            if (apartmentTenantSummaryState.success) {
                const respData = apartmentTenantSummaryState.data as Record<string, unknown>[]
                if (respData.length === 0 && dataFetched && !isLLE) {
                    setShowGuidedModal(true);
                }
                setUnitData(sortByTenantId(respData))
            } else if (apartmentTenantSummaryState.error && !isToastVisible) {
                toast.warn(t("noDataFound") as string)
                setisToastVisible(true)
                if (dataFetched && !isLLE) {
                    setShowGuidedModal(true);
                }
            }
        }
    }, [t, apartmentTenantSummaryState, isToastVisible, dataFetched, isLLE])

    const convertLookupsToDropdownOptions = (elsLookups: Record<string, unknown>[], valueKey: string, textKey: string) => {
        const activeLookups = elsLookups.filter((lookup) => (lookup["activeFlag"] as string) === "Y")
        const options: Option[] = activeLookups.map((lookup) => {
            return {
                value: lookup[valueKey] as string,
                text: lookup[textKey] as string
            }
        })
        return [{ value: "", text: "" }, ...options]
    }

    useEffect(() => {
        if (currentSnapshotId !== Number(localStorage.getItem("currentSnapshotId"))) {
            setCurrentSnapshotId(Number(localStorage.getItem("currentSnapshotId")));
            dispatch(resetGetTenantSummaryBySnapshotId());
        }
    }, [currentSnapshotId, dispatch]);

    useEffect(() => {
        if (elsLookupsState.success) {
            // unitStyleType
            const sortedUnitStyleTypeOptions = convertLookupsToDropdownOptions((elsLookupsState.dropdowns["unitStyleType"] as Record<string, unknown>[]).sort((a, b) => (a.unitStylePosition as number) - (b.unitStylePosition as number)), "unitStyleCode", "unitStyleDescription")
            setStyleOptions(sortedUnitStyleTypeOptions)
            // UnitSubtype
            if (isLLE) {
                const options = convertLookupsToDropdownOptions((elsLookupsState.dropdowns["landLeaseSubType"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number)), "unitSubtypeCode", "unitSubtypeDescription")
                setUnitSubtype3Options(options)
            } else {
                const apartmentOptions = convertLookupsToDropdownOptions((elsLookupsState.dropdowns["multiresSubType"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number)), "unitSubtypeCode", "unitSubtypeDescription")
                const rowHouseOptions = convertLookupsToDropdownOptions((elsLookupsState.dropdowns["multiresRowHouseSubType"] as Record<string, unknown>[]).sort((a, b) => (a.unitSubtypePosition as number) - (b.unitSubtypePosition as number)), "unitSubtypeCode", "unitSubtypeDescription")
                setUnitTypeSubtypeMap({
                    "1": apartmentOptions,
                    "2": rowHouseOptions,
                    "3": rowHouseOptions
                })
            }
            // tenancyType
            const tenancyTypeOptions = convertLookupsToDropdownOptions((elsLookupsState.dropdowns["tenancyType"] as Record<string, unknown>[]), "tenancyTypeCode", "tenancyTypeDescription")
            setTenancyTypeOptions(tenancyTypeOptions)
        } else if (!elsLookupsState.success && !elsLookupsState.loading) {
            dispatch(getElsLookups())
        }
    }, [dispatch, elsLookupsState, isLLE])

    useEffect(() => {
        if (unitData.length) {
            setIsResidential(true)
        } 
    }, [unitData])

    useEffect(() => {
        if (showGuidedModal && isResidential) {
          // ReactGA event to track MultiRes wizard start
          ReactGA.event({
            category: 'MultiRes Guided Wizard',
            action: 'MultiRes Guided Wizard',
            label: `Start`
          });
          console.log("Triggered MultiRes start event")
        }
    }, [showGuidedModal, isResidential]);
      
    const mapDesignTypeByTT = (tenancyTypeCode: string | null) => {
        const mappedOptions: Option[] = []
        if (tenancyTypeCode) {
            const dtMappedBytt = (elsLookupsState.dropdowns["tenancyType"] as Record<string, unknown>[])
                .filter(tt => tt.tenancyTypeCode === tenancyTypeCode)[0]["unitDesignTypeCode"] as string[]

            const dt = (elsLookupsState.dropdowns["designType"] as Record<string, unknown>[])
                .filter((dt) => dtMappedBytt.includes(String(dt.unitDesignTypeCode)))

            dt.forEach((option) => {
                if ((option["activeFlag"] as string) === "Y") {
                    mappedOptions.push({ value: option["unitDesignTypeCode"] as string, text: option["unitDesignTypeDescription"] as string })
                }
            })
        }

        return mappedOptions
    }

    let headings = [
        <th key="column-2-header" title={t("style-tooltip")}>{t("style")} <Asterisk /></th>,
        <th key="column-1-header" title={t("type-tooltip")}>{t("type")} <Asterisk /></th>,
        <th key="column-3-header" title={t("baths-tooltip")}>{t("baths")} <Asterisk /></th>,
        <th key="column-4-header" title={t("size-tooltip")}>{t("size")}</th>,
        <th key="column-5-header" title={t("rent-tooltip")}>{t("rent")} <Asterisk /></th>,
        <th key="column-6-header"></th>,
        <th key="column-7-header"></th>
    ]
    if (isLLE) {
        headings = [
            <th key="column-1-header" title={t("type-tooltip")}>{t("site-type")}</th>,
            <th key="column-3-header" title={t("unitCount-tooltip")}>{t("unitCount")} <Asterisk /></th>,
            <th key="column-4-header" title={t("size-tooltip")}>{t("size")}</th>,
            <th key="column-5-header" title={t("rent-tooltip")}>{t("rent")} <Asterisk /></th>,
            <th key="column-6-header"></th>,
            <th key="column-7-header"></th>
        ]
    }
    if (isMultiresMixeduse) {
        headings = [
            <th key="column-2-header" title={t("style-tooltip")}>{t("style")} <Asterisk /></th>,
            <th key="column-1-header" title={t("type-tooltip")}>{t("type")} <Asterisk /></th>,
            <th key="column-15-header" title={t("unitCount-tooltip")}>{t("unitCount")} <Asterisk /></th>,
            <th key="column-16-header" title={t("furnishedFlag-tooltip")}>{t("furnishedFlag")}</th>,
            <th key="column-3-header" title={t("baths-tooltip")}>{t("baths")} <Asterisk /></th>,
            <th key="column-4-header" title={t("size-tooltip")}>{t("size")}</th>,
            <th key="column-17-header" title={t("renovatedUnitCount-tooltip")}>{t("renovatedUnitCount")}</th>,
            <th key="column-18-header" title={t("vacantUnitCount-tooltip")}>{t("vacantUnitCount")}</th>,
            <th key="column-5-header" title={t("rent-tooltip")}>{t("rent")} <Asterisk /></th>,
            <th key="column-6-header"></th>,
            <th key="column-7-header"></th>
        ]
    }

    if (isModifiedSubmission) {
        headings = [
            <th key="column-9-header">BIN</th>,
            <th key="column-10-header">Tenancy Type</th>,
            <th key="column-11-header">Design Type</th>,
            <th key="column-12-header">Analysis</th>,
            <th key="column-13-header">Priority</th>,
            <th key="column-14-header">Link</th>,
        ].concat(headings)
    }

    const bathsOptions = [
        { value: "", text: "" },
        { value: 1, text: "1" },
        { value: 1.5, text: "1.5" },
        { value: 2, text: "2" },
        { value: 2.5, text: "2.5" },
        { value: 3, text: "3" },
        { value: 3.5, text: "3.5" },
        { value: 4, text: "4" },
        { value: 4.5, text: "4.5" },
        { value: 5, text: "5" }
    ]

    const analysisOptions = [
        { value: "", text: "" },
        { value: "Y", text: "Y" },
        { value: "N", text: "N" },
        { value: "X", text: "X" },
        { value: "F", text: "F" }
    ]

    const formatOutput = (value: string | number | null | undefined) => {
        if (value === null || value === undefined) {
            return ""
        } else {
            return value.toString()
        }
    }

    const updateValue = (e: React.ChangeEvent, index: number, key: string, isNumber?: boolean) => {
        // let errorMessage = ''
        // let hideErrorCross = false
        // let isError = false
        const dataSet = [...unitData]
        const target = e.target as HTMLSelectElement | HTMLInputElement
        if (target.type === "checkbox") {
            dataSet[index][key] = (target as HTMLInputElement).checked
        } else {
            const value = target.value.trim()
            if (isNumber && value !== "") {
                let numericValue = Number(value)
                if (isNaN(numericValue)) {
                    dataSet[index][key] = null
                    target.value = ""
                } else if (numericValue < 0) {
                    // errorMessage = 'Value must be positive'
                    // hideErrorCross = true
                    // isError = true
                    numericValue = -1 * numericValue
                    dataSet[index][key] = numericValue
                    target.value = String(numericValue)
                } else if (["unitCount", "renovatedUnitCount", "vacantUnitCount"].includes(key)) {
                    numericValue = Math.trunc(numericValue)
                    dataSet[index][key] = numericValue
                    target.value = String(numericValue)
                } else {
                    dataSet[index][key] = numericValue
                }
            } else {
                dataSet[index][key] = value === "" ? null : value
                if (key === "styleTypeCode" && Object.keys(unitTypeSubtypeMap).length) {
                    setUnitSubtype3Options(unitTypeSubtypeMap[value])
                }
            }
        }
        // dataSet[index][`${key}_error`] = { errorMessage, hideErrorCross, isError }
        setUnitData(dataSet)
        setHasRowBeenChanged(true)
    }

    const addUnit = (e: React.MouseEvent) => {
        e.preventDefault();

        const newUnit = {
            unitTypeCode: '3',
            unitSubtypeCode: '',
            styleTypeCode: '',
            bathCount: '',
            netLeasableArea: '',
            monthlyMarketRent: '',
            new: true
        }

        const updatedData = [newUnit, ...unitData]
        setUnitData(updatedData)
        setEditableUnitIndex(0)
    }

    const removeUnit = (e: React.MouseEvent, index: number) => {
        e.preventDefault()
        const dataSet = [...unitData]
        const unit = dataSet[index]
        if (unit['tenantId'] !== undefined) {
            const tenantId = unit['tenantId'] as string
            dispatch(deleteIncomeUnitById(currentSnapshotId, tenantId))

        } else {
            dataSet.splice(index, 1)
            setUnitData(dataSet)
            setEditableUnitIndex(null)
        }
    }

    const toggleLocked = (e: React.MouseEvent, index: number) => {
        // Toggle lock/unlock state
        e.preventDefault()
        if (editableUnitIndex === null) {
            setEditableUnitIndex(index)
            return
        }

        // Check if the required fields are filled
        if (editableUnitIndex === index) {
            const unit = unitData[index]
            if (!validUnit(unit)) {
                return
            }
            saveRow(index)
            setEditableUnitIndex(null)
        }
    }

    const saveRow = (index: number) => {
        if (!hasRowBeenChanged && !unitData[index].new) {
            setEditableUnitIndex(null)
            return
        }

        const unit = unitData[index];
        if (validUnit(unit)) {
            if (isNewUnit(unit)) {
                setModifiedIdxs([index])
                dispatch(postIncomeUnit(currentSnapshotId, unit))
            } else {
                dispatch(putIncomeUnit(currentSnapshotId, unit))
            }
            setEditableUnitIndex(null)
            setHasRowBeenChanged(false)
        }
    }

    const isNewUnit = (unit: Record<string, unknown>) => {
        return 'new' in unit
    }

    const requiredFields = (unit: Record<string, unknown>) => {
        let result = true
        if ((unit['unitSubtypeCode'] === '' || unit['unitSubtypeCode'] === null) && !isLLE) {
            result = false
        } else if ((unit['styleTypeCode'] === '' || unit['styleTypeCode'] === null) && !isLLE) {
            result = false
        } else if ((unit['bathCount'] === '' || unit['bathCount'] === null) && !isLLE) {
            result = false
        } else if (unit['monthlyMarketRent'] === '' || unit['monthlyMarketRent'] === null) {
            result = false
        } else if ((unit['unitCount'] === '' || unit['unitCount'] === null) && isMultiresMixeduse) {
            result = false
        }

        return result
    }

    const positiveNumbers = (unit: Record<string, unknown>) => {
        let result = true
        if (parseInt(unit['monthlyMarketRent'] as string) < 0) {
            result = false
        } else if (parseInt(unit['netLeasableArea'] as string) < 0) {
            result = false
        } else if (isMultiresMixeduse) {
            if (unit["unitCount"] as number < 0
                || unit["renovatedUnitCount"] as number < 0
                || unit["vacantUnitCount"] as number < 0) {
                result = false
            }
        }

        return result
    }

    const validUnit = (unit: Record<string, unknown>) => {
        if (localStorage.getItem("isInternalUser")) {
            //
        } else {
            if (!requiredFields(unit)) {
                toast.error(t("validation-error") as string)
                return false
            }
        }

        if (!positiveNumbers(unit)) {
            toast.error(t("validation-error-negative") as string)
            return false
        }

        return true
    }

    const hasUnsavedRow = () => {
        return editableUnitIndex !== null;
    };

    const buildTableContent = (): JSX.Element => {
        const tableBody: JSX.Element[] = []
        for (let index = numberPerPage * (currentPage - 1); index < Math.min(unitData.length, numberPerPage * currentPage); index++) {
            const isLocked = editableUnitIndex !== index;
            tableBody.push(<tr key={`table-row-${index}`}>
                {isModifiedSubmission && <>
                    <td>
                        <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`bin-${index}`}
                            id={`bin-${index}`} type="text" value={formatOutput(unitData[index].bin as number | null)} onChange={e => updateValue(e, index, "bin", true)} ariaLabel={`bin-${index}`} />
                    </td>
                    <td>
                        <Dropdown readOnly={isLocked} options={tenancyTypeOptions} value={formatOutput(unitData[index].tenancyTypeCode as string | null)}
                            onChange={e => updateValue(e, index, "tenancyTypeCode")} ariaLabel={`tt-${index}`} />
                    </td>
                    <td>
                        <Dropdown readOnly={isLocked} options={mapDesignTypeByTT(unitData[index].tenancyTypeCode as string | null)} value={formatOutput(unitData[index].designTypeCode as string | null)}
                            onChange={e => updateValue(e, index, "designTypeCode")} ariaLabel={`dt-${index}`} />
                    </td>
                    <td>
                        <Dropdown readOnly={isLocked} options={analysisOptions} value={formatOutput(unitData[index].analysisFlag as string | null)}
                            onChange={e => updateValue(e, index, "analysisFlag")} ariaLabel={`analysis-${index}`} />
                    </td>
                    <td>
                        <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`priority-${index}`}
                            id={`priority-${index}`} type="text" value={formatOutput(unitData[index].priority as number | null)} onChange={e => updateValue(e, index, "priority", true)} ariaLabel={`priority-${index}`} />
                    </td>
                    <td>
                        <Input disabled={isLocked} className={isLocked ? 'locked-input' : ''} name={`linkFlag-${index}`}
                            id={`linkFlag-${index}`} type="checkbox" value={unitData[index].linkFlag as string} onChange={e => updateValue(e, index, "linkFlag", true)} ariaLabel={`linkFlag-${index}`} />
                    </td>
                </>}
                {
                    !isLLE && <>
                        <td title={t("style")}>
                            <Dropdown readOnly={isLocked} options={styleOptions} value={formatOutput(unitData[index].styleTypeCode as string | null)} onChange={e => updateValue(e, index, "styleTypeCode")} ariaLabel={t("style") + " " + index} />
                        </td>
                    </>
                }
                <td title={t("type")}>
                    <Dropdown readOnly={isLocked} options={isLLE ? unitSubtype3Options : ((Object.keys(unitTypeSubtypeMap).length && unitData[index].styleTypeCode) ? unitTypeSubtypeMap[unitData[index].styleTypeCode as string] : [{ value: "", text: "" }])} value={formatOutput(unitData[index].unitSubtypeCode as string | null)} onChange={e => updateValue(e, index, "unitSubtypeCode")} ariaLabel={t("type") + " " + index} />
                </td>
                {(isMultiresMixeduse || isLLE) &&
                        <td title={t("unitCount")} className={unitData[index]['unitCount_error']?.isError ? 'error-cell' : ''}>
                            <Input readOnly={isLocked} className={!isLocked && unitData[index]['unitCount_error']?.isError ? 'error-input' : (isLocked ? 'locked-input' : '')} name={`unitCount-${index}`} id={`unitCount-${index}`} type="text" value={formatOutput(unitData[index].unitCount as number | null)} onChange={e => updateValue(e, index, "unitCount", true)} ariaLabel={t("unitCount") + " " + index} positiveOnly={true} errorMessage={unitData[index]['unitCount_error']?.errorMessage} hideErrorCross={unitData[index]['unitCount_error']?.hideErrorCross} />
                        </td>
                }
                {isMultiresMixeduse && 
                        <td><input disabled={isLocked} className={isLocked ? 'locked-input' : ''}
                            type="checkbox" checked={Boolean(unitData[index].furnishedFlag)} onChange={e => updateValue(e, index, "furnishedFlag", true)} /></td>
                }
                {!isLLE &&
                    <td title={t("baths")}>
                        <Dropdown readOnly={isLocked} options={bathsOptions} value={formatOutput(unitData[index].bathCount as number | null)} onChange={e => updateValue(e, index, "bathCount", true)} ariaLabel={t("baths") + " " + index} />
                    </td>
                }
                <td title={t("size")} className={unitData[index]['netLeasableArea_error']?.isError ? 'error-cell' : ''}>
                    <Input readOnly={isLocked} className={!isLocked && unitData[index]['netLeasableArea_error']?.isError ? 'error-input' : (isLocked ? 'locked-input' : '')} name={`unit-size-${index}`} id={`unit-size-${index}`} type="text" value={formatOutput(unitData[index].netLeasableArea as number | null)} onChange={e => updateValue(e, index, "netLeasableArea", true)} ariaLabel={t("size") + " " + index} positiveOnly={true} errorMessage={unitData[index]['netLeasableArea_error']?.errorMessage} hideErrorCross={unitData[index]['netLeasableArea_error']?.hideErrorCross} />
                </td>
                {isMultiresMixeduse &&
                    <>
                        <td title={t("renovatedUnitCount")} className={unitData[index]['renovatedUnitCount_error']?.isError ? 'error-cell' : ''}>
                            <Input readOnly={isLocked}
                                className={!isLocked && unitData[index]['renovatedUnitCount_error']?.isError ? 'error-input' : (isLocked ? 'locked-input' : '')}
                                name={`renovatedUnitCount-${index}`} id={`renovatedUnitCount-${index}`} type="text"
                                value={formatOutput(unitData[index].renovatedUnitCount as number | null)}
                                onChange={e => updateValue(e, index, "renovatedUnitCount", true)} ariaLabel={t("renovatedUnitCount") + " " + index}
                                positiveOnly={true} errorMessage={unitData[index]['renovatedUnitCount_error']?.errorMessage}
                                hideErrorCross={unitData[index]['renovatedUnitCount_error']?.hideErrorCross} />
                        </td>
                        <td title={t("vacantUnitCount")} className={unitData[index]['vacantUnitCount_error']?.isError ? 'error-cell' : ''}>
                            <Input readOnly={isLocked}
                                className={!isLocked && unitData[index]['vacantUnitCount_error']?.isError ? 'error-input' : (isLocked ? 'locked-input' : '')}
                                name={`vacantUnitCount-${index}`} id={`vacantUnitCount-${index}`} type="text"
                                value={formatOutput(unitData[index].vacantUnitCount as number | null)}
                                onChange={e => updateValue(e, index, "vacantUnitCount", true)} ariaLabel={t("vacantUnitCount") + " " + index}
                                positiveOnly={true} errorMessage={unitData[index]['vacantUnitCount_error']?.errorMessage}
                                hideErrorCross={unitData[index]['vacantUnitCount_error']?.hideErrorCross} />
                        </td>
                    </>
                }
                <td title={t("rent")} className={unitData[index]['monthlyMarketRent_error']?.isError ? 'error-cell' : ''}>
                    <Input readOnly={isLocked} name={`rent-${index}`} className={!isLocked && unitData[index]['monthlyMarketRent_error']?.isError ? 'error-input' : (isLocked ? 'locked-input' : '')} id={`rent-${index}`} type="text" value={formatOutput(unitData[index].monthlyMarketRent as number | null)} onChange={e => updateValue(e, index, "monthlyMarketRent", true)} ariaLabel={t("rent") + " " + index} positiveOnly={true} errorMessage={unitData[index]['monthlyMarketRent_error']?.errorMessage} hideErrorCross={unitData[index]['monthlyMarketRent_error']?.hideErrorCross} />
                </td>
                <td>
                    <Button onClick={(e) => toggleLocked(e, index)} text={<FontAwesomeIcon icon={editableUnitIndex === index ? faLockOpen : faLock} className={(editableUnitIndex === null || editableUnitIndex === index) && !isReadOnly ? "trash" : "trash-off"} />} ariaLabel={"lock " + index} disabled={isReadOnly} />
                </td>
                <td>
                    <Button onClick={(e) => removeUnit(e, index)} text={<FontAwesomeIcon icon={faTrashCan} className={(editableUnitIndex === null || editableUnitIndex === index) && !isReadOnly ? "trash" : "trash-off"} />} ariaLabel={t("remove") + " " + index} disabled={(editableUnitIndex !== null && editableUnitIndex !== index) || isReadOnly} />
                </td>
            </tr>)
        }
        return (<>{tableBody}</>)
    }

    const cancelWizard = () => {
        // ReactGA event to track MultiRes wizard cancellation
        ReactGA.event({
            category: 'MultiRes Guided Wizard',
            action: 'MultiRes Guided Wizard',
            label: `Exit using X icon`
        });
        console.log("Triggered MultiRes exit event")
        setShowGuidedModal(false);
    }
    return (
        <div className='suite-breakdown-container'>
            {showResidentialQuestion ?
                <div className='suite-breakdown-rent-to-tenant'>
                    <p>{t("tenants") as string}</p>
                    <RadioButton name="rent-to-tenant-radio-yes" id="rent-to-tenant-radio-yes" text={t("confirm") as string} textPosition="left" value="true" onChange={() => {
                        setIsResidential(true)
                        setProfileData({ ...profileData, hasApartmentFlag: true })
                    }} currentValue={isResidential} />
                    <RadioButton name="rent-to-tenant-radio-no" id="rent-to-tenant-radio-no" text={t("cancel") as string} textPosition="left" value="false" onChange={() => {
                        setIsResidential(false)
                        setProfileData({ ...profileData, hasApartmentFlag: false })
                    }} currentValue={isResidential} />
                </div> : <></>
            }
            {(showGuidedModal && !isReadOnly && isResidential && !isLLE) &&
            <Modal title={t('guided-experience-wizard')} onCloseModal={cancelWizard} 
            class="guided-experience-wizard-modal">
                <MultiresWizard snapshotId={Number(localStorage.getItem('currentSnapshotId') as string)} onCancel={() => setShowGuidedModal(false)} />
            </Modal>}
            {isResidential && (
                <span className='breakdown-table-section'>
                    <div className='suite-breakdown-header-row'>
                        <p className='imp-note'>{t("info-reflective", { ns: 'common' })}</p>
                        <div className='suite-control-group'>
                            <div className="unit-table-control-group">
                                {(!isReadOnly && !isLLE) && <Button onClick={() => {
                                    setShowGuidedModal(true);
                                    setEditableUnitIndex(null);
                                }} text={<FontAwesomeIcon
                                    icon={faWandMagicSparkles}
                                />} ariaLabel="guidedExperienceWizard"
                                    disabled={editableUnitIndex !== null}
                                    className={editableUnitIndex !== null ? `guided-wizard-btn disabled-button` : `guided-wizard-btn`} />}
                            </div>
                            {!isReadOnly && <Button disabled={hasUnsavedRow()} type={hasUnsavedRow() ? "disabled" : "submit"} onClick={e => addUnit(e)} text={t("add") as string} />}
                        </div>
                    </div>

                    {(apartmentTenantSummaryState.loading || updateUnitState.loading || createUnitState.loading || deleteIncomeUnitState.loading || (unitData && unitData.length > 0)) &&
                        <DataTable headings={headings}
                            className="suite-breakdown-table"
                            totalPages={Math.ceil(unitData.length / numberPerPage)}
                            currentPage={currentPage} changePage={setCurrentPage}
                            numberPerPage={numberPerPage}
                            changeNumberPerPage={setNumberPerPage}>{buildTableContent()}
                        </DataTable>}
                </span>)
            }
        </div>
    )
}