import React from "react";
import './Checkbox.css'
import { useReadOnly } from "../../../utils/ReadOnlyContext";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    checkboxclassname?: string,
    checkboxContainerClassName?: string
    hasErrors?: boolean,
    ariaLabel?: string,
    onChange?: () => void
}

export default function Checkbox(props: CheckboxProps): JSX.Element {
    const { isReadOnly } = useReadOnly()
    
    return (
        <>
            <label className={`checkbox-container ${props.checkboxContainerClassName ?? ""}`} aria-label={props.ariaLabel ? props.ariaLabel : props.name}>
                <input type="checkbox" {...props}
                    onChange={props.onChange}
                    aria-label={props.ariaLabel ? props.ariaLabel : props.name}
                    disabled={isReadOnly || props.disabled} />
                <span className={`checkmark ${props.checkboxclassname??""}${props.hasErrors ? " form-error" : ""}`}></span>
            </label>
        </>
    )
}
