import { API_REQUEST, GET_COMMENT, RESET_UPDATE_COMMENT, SAVE_RESULT } from "../../actionTypes/actionsTypes";
import { AppAction, LocalAction } from "../../types";

// Manage comment action
export const getComment = (campaignId: string | number): AppAction => ({
    type: API_REQUEST,
    payload: Object.assign({
        url: `/comments/${campaignId}`,
        next: GET_COMMENT,
        method: 'GET',
        authorization: true
    })
});

export const postComment = (campaignId: string | number, commentData: Record<string, unknown>, triggerValidation: boolean): AppAction => ({
    type: API_REQUEST,
    payload: Object.assign({
        url: `/comments/${campaignId}/add-comment`,
        data: { "comment": commentData, "triggerValidation": triggerValidation },
        next: SAVE_RESULT,
        method: 'POST',
        authorization: true
    })
});

export const putComment = (campaignId: string | number, commentId: string | number, commentData: Record<string, unknown>, triggerValidation: boolean): AppAction => ({
    type: API_REQUEST,
    payload: Object.assign({
        url: `comments/${campaignId}/update-comment/${commentId}`,
        data: { "comment": commentData, "triggerValidation": triggerValidation },
        next: SAVE_RESULT,
        method: 'PUT',
        authorization: true
    })
});

export const resetUpdateComment = (): LocalAction => ({
    type: RESET_UPDATE_COMMENT
});