/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
 import React, { useState, useEffect, useRef } from 'react'
 import ReactGA from 'react-ga4';
 import { useTranslation } from 'react-i18next'
 import { useDispatch, useSelector } from 'react-redux'
 import { useNavigate } from 'react-router-dom'
 import { toast } from 'react-toastify'
 import { updateCampaignStatus } from '../../../redux/actions/campaign/updateStatus'
 import { getElsLookups } from '../../../redux/actions/lookups/lookups'
 import { getProfileOverview } from '../../../redux/actions/profile/profile'
 import { setReadOnly } from '../../../redux/actions/readOnly/readOnly'
 import { EXTERNAL_IN_PROGRESS, RESET_PROPERTIES, RESET_UPDATE_CAMPAIGN_STATUS } from '../../../redux/actionTypes/actionsTypes'
 import { RootState } from '../../../redux/reducers/root'
 import { UpdateCampaignState } from '../../../redux/reducers/getCampaign/updateCampaignStatusReducer'
 import { GetElsLookupsState } from '../../../redux/reducers/getLookups/getElsLookupsReducer'
 import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer'
 import CampaignList from '../../../components/CampaignList/CampaignList'
 import Button from '../../../components/common/Button/Button'
 import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner'
 import LandingBanner from '../../../components/LandingBanner/LandingBanner'
 import Header from '../../../layouts/Header/Header'
 import Footer from '../../../layouts/Footer/Footer'
 import { isCampaignListDisplayed, isThereCampaignToImport } from '../../../utils/campaignList';
 import "./Start.css"
 import { formatRoll } from '../../../utils/formatter'
 
 export default function Start(): JSX.Element {
     const { t } = useTranslation('transition')
     const navigate = useNavigate()
     const dispatch = useDispatch()
     const profileId = localStorage.getItem('profileId') as string
     const [isReturnTypeSelected, setIsReturnTypeSelected] = useState(false)
     const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups)
     const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
     const updateCampaignStatusState: UpdateCampaignState = useSelector((state: RootState) => state.campaignReducer.updateCampaignStatus)
     const getProfileOverviewDispatched = useRef(false)
     const rollNumber = window.location.href.split('/')[5];
 
     useEffect(() => {
         if (!getProfileOverviewDispatched.current &&
             !(profileOverview?.profile?.profile_id === Number(profileId))
         ) {
             dispatch(getProfileOverview(profileId))
             getProfileOverviewDispatched.current = true
         }
     }, [dispatch, profileId, profileOverview])
 
     useEffect(() => {
         if (profileOverview && profileOverview.status && profileOverview.profile.profile_id === Number(profileId)) {
             if (getProfileOverviewDispatched.current) {
                 getProfileOverviewDispatched.current = false
             }
             if (profileOverview.status === 200) {
                 if (updateCampaignStatusState.updated) {
                     dispatch({ type: RESET_UPDATE_CAMPAIGN_STATUS })
                     navigate('/portal')
                 }
             } else {
                 if (profileOverview.message) {
                     toast.error(profileOverview.message)
                 }
             }
         }
     }, [dispatch, navigate, profileId, profileOverview, updateCampaignStatusState.updated])
 
     const showImportDataModal = (e: React.MouseEvent) => {
         e.preventDefault()
         ReactGA.event({
             category: 'Start Page',
             action: 'Select New or Import option',
             label: `Start return: Import`,
         });
         console.log("Triggered start page event")
         localStorage.setItem("incomeReturnType", "import")
         dispatch(setReadOnly(false))
         setIsReturnTypeSelected(true)
 
         if (profileOverview.profile.profile_type_code !== "GEV") {
             fetchLookups()
         }
         dispatch(updateCampaignStatus(EXTERNAL_IN_PROGRESS,
             profileId,
             String(profileOverview.profile.current_campaign.campaignId),
             "true")) // Set isCopySnapshot to true only when import is selected
     }
 
     const startNewReturn = (e: React.MouseEvent) => {
         e.preventDefault()
         ReactGA.event({
             category: 'Start Page',
             action: 'Select New or Import option',
             label: `Start return: New`,
         });
         console.log("Triggered start page event")
         localStorage.setItem("incomeReturnType", "new")
         dispatch(setReadOnly(false))
         setIsReturnTypeSelected(true)
         if (profileOverview.profile.profile_type_code !== "GEV") {
             fetchLookups()
         }
         dispatch(updateCampaignStatus(EXTERNAL_IN_PROGRESS,
             profileId,
             String(profileOverview.profile.current_campaign.campaignId),
             "false")) // Set isCopySnapshot to false if new is selected
     }
 
     const fetchLookups = () => {
         // Get lookups if they are not found in localstorage
         if (!elsLookupsState.success && profileOverview.profile.profile_type_code !== "GEV") { // disable ELS calls for GEV portfolio
             dispatch(getElsLookups())
         }
     }
 
     useEffect(() => {
         // If profile type code is GEV, system doesn't check for ELS response success before navigating to portal
         if (isReturnTypeSelected && (profileOverview.profile.profile_type_code === "GEV" || elsLookupsState.success)) {
             if (updateCampaignStatusState.updated) {
                 // Save current property selection to localstorage for easy access to ids
                 localStorage.setItem('rollNumber', localStorage.getItem('rollNumber') as string)
                 localStorage.setItem('profileId', String(updateCampaignStatusState.campaign['profileId']))
                 localStorage.setItem('activeCampaignYear', String(updateCampaignStatusState.campaign['campaignYear']))
                 localStorage.setItem('campaignId', String(updateCampaignStatusState.campaign['campaignId']))
                 localStorage.setItem('campaignStatusCode', String(updateCampaignStatusState.campaign['statusCode']));
                 localStorage.setItem('currentSnapshotId', String(updateCampaignStatusState.campaign['currentSnapshotId']))
                 dispatch({ type: RESET_PROPERTIES })
                 dispatch(getProfileOverview(String(updateCampaignStatusState.campaign['profileId'])))
                 getProfileOverviewDispatched.current = true
             } else {
                 if (updateCampaignStatusState.message) {
                     toast.error(updateCampaignStatusState.message)
                 }
             }
         }
     }, [dispatch, elsLookupsState.success, isReturnTypeSelected, updateCampaignStatusState, profileOverview.profile.profile_type_code])
 
     return (<>
         <Header showAutoSave={false} isInternalUser={false} />
         <LandingBanner />
         {
             (profileOverview?.profile?.profile_id === Number(profileId)) &&
             <>
                 <LoadingSpinner loading={updateCampaignStatusState.loading || profileOverview.loading}>
                     <div className='start-body-container'>
                         <div className='start-body'>
                             <div className='start-heading-section'>
                                 {
                                     profileOverview.profile.profile_type_code !== "GEV" && <>
                                         <h1 className='start-heading'>{t("start.heading")}</h1>
                                         <div className='import-block'>
                                             <p>
                                                 {isThereCampaignToImport(profileOverview, profileOverview.profile.current_campaign.year) ? t('start.import-question') : t('start.no-import-question')}
                                             </p>
                                         </div>
                                     </>
                                 }
                                 <span className="strong">{formatRoll(rollNumber)}</span>
                                 <div className="transition-btn-group">
                                     <div className="button-group justify-content-center">
                                         {
                                             profileOverview.profile.profile_type_code !== "GEV" && isThereCampaignToImport(profileOverview, profileOverview.profile.current_campaign.year) ?
                                                 <>
                                                     <Button onClick={e => showImportDataModal(e)} type="submit" text={t("start.yes")} />
                                                     <Button onClick={e => startNewReturn(e)} type="secondary" text={t("start.no")} />
                                                 </>
                                                 :
                                                 <Button onClick={e => startNewReturn(e)} type="secondary" text={t("start.no-previous-campaigns")} />
 
                                         }
                                         <Button onClick={() => navigate("/landing")} type="secondary" text={t("return")}
                                             className='start-page-return-btn' />
                                     </div>
                                 </div>
                             </div>
                         </div>
                         {
                             isCampaignListDisplayed(profileOverview) &&
                             <div className="campaign-list-wrapper">
                                 <div className="campaign-list">
                                     <CampaignList />
                                 </div>
                             </div>
                         }
                     </div>
                 </LoadingSpinner>
             </>
         }
         <div className="start-footer">
             <Footer />
         </div>
     </>)
 }