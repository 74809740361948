import React from 'react';
import "./CommercialUnit.css";
import OperatingExpenseRow from './OperatingExpenseRow';

interface OperatingExpenseProps {
    formData: Record<string, unknown>,
    disabled?: boolean
}

export default function OperatingExpense(props: OperatingExpenseProps): JSX.Element {
    return (
        <tbody>
            <OperatingExpenseRow 
                checked={props.formData.includeInsuranceFlag as boolean | undefined} 
                disabled={props.disabled}
                name="includeInsuranceFlag" 
                tooltipKey='insurance-tooltip' 
                headingKey='insurance'/>
            <OperatingExpenseRow 
                checked={props.formData.includeManagementFlag as boolean | undefined} 
                disabled={props.disabled}
                name="includeManagementFlag" 
                tooltipKey='management-tooltip' 
                headingKey='management'/>
            <OperatingExpenseRow 
                checked={props.formData.includeMaintenanceFlag as boolean | undefined} 
                disabled={props.disabled}
                name="includeMaintenanceFlag" 
                tooltipKey='maintenance-tooltip' 
                headingKey='maintenance'/>
            <OperatingExpenseRow 
                checked={props.formData.includeHydroFlag as boolean | undefined} 
                disabled={props.disabled}
                name="includeHydroFlag" 
                tooltipKey='hydro-tooltip' 
                headingKey='hydro'/>
            <OperatingExpenseRow 
                checked={props.formData.includeWaterFlag as boolean | undefined} 
                disabled={props.disabled}
                name="includeWaterFlag" 
                tooltipKey='water-tooltip' 
                headingKey='water'/>
            <OperatingExpenseRow 
                checked={props.formData.includeHvacFlag as boolean | undefined} 
                disabled={props.disabled}
                name="includeHvacFlag" 
                tooltipKey='hvac-tooltip' 
                headingKey='hvac'/>
        </tbody>
    );
}
