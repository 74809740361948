/**
 * Update: 18/04/2024 - Mariam Bawa - Refactor code to use IncomeAttributeTableFragmentGenerator to generate table content and cast data to IncomeAttributeLookupObject
 Update: 18/04/2024 - Mariam Bawa - Added thead element in markup to meet AODA standards. 
Element is then hidden from UI using CSS
*/
import React from "react";
import IncomeAttributeLookupObject from "../../../types/lookups/IncomeAttributeLookupObject";
import IncomeAttributeTableFragmentGenerator from "../../../utils/TableGenerator/IncomeAttributeTableFragmentGenerator";
import { ProfileOverviewState } from "../../../redux/reducers/getProfile/getProfileOverviewReducer";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/root";

export interface ExpenseTabProps {
  handleTotalExpense: (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => void,
  handleCommentChange: (value: string, name: string) => void,
  lookups: IncomeAttributeLookupObject[],
  incomeAttributes?: Record<string, unknown>[],
  errors?: Record<string, string>
}

export default function ExpenseTab(props: ExpenseTabProps): JSX.Element {
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const lookupType = profileOverview.profile.profile_type_code.toLowerCase();
  return (
    <>
      {
        props.lookups &&
        <table className="table-with-border pie-table accordion-content">
          <thead>
            <tr>
              <th id="attribute-label" scope="col"></th>
              <th id="attribute-value" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {
              props.lookups.map((attr, key) => {
                let incomeAttributesData: Record<string, unknown> | undefined = undefined;
                if (props.incomeAttributes) {
                  incomeAttributesData = props.incomeAttributes.find(({ code }) => code === attr.incomeAttributeCode);
                }
                if ((attr.incomeAttributeCode === '52' && lookupType !== "glf") || ((attr.incomeAttributeCode === "62" || attr.incomeAttributeCode === "69") && lookupType === "lle") || attr.incomeAttributeCode === '84' || attr.incomeAttributeCode === '87') {
                  return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                    data={incomeAttributesData} errors={props.errors}
                    showSubheading={false} hasInputLabel={true} isOtherLabel={false}
                    handleCommentChange={props.handleCommentChange}
                    valueChangeHandler={props.handleTotalExpense} isRequiredField={false} />
                } else if (["62", "65", "69", "75", "77"].includes(attr.incomeAttributeCode)) {
                  return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                    data={incomeAttributesData} errors={props.errors}
                    showSubheading={false} hasInputLabel={true} isPlaceHolderWithAttrDesc={true}
                    isOtherLabel={false}
                    handleCommentChange={props.handleCommentChange}
                    valueChangeHandler={props.handleTotalExpense} isRequiredField={false} />
                } else if (attr.incomeAttributeCode === '54' || attr.incomeAttributeCode === '49') {
                  return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                    data={incomeAttributesData} errors={props.errors}
                    showSubheading={false} hasInputLabel={false} isOtherLabel={false}
                    handleCommentChange={props.handleCommentChange}
                    valueChangeHandler={props.handleTotalExpense} isRequiredField={true} />
                } else {
                  return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                    data={incomeAttributesData} errors={props.errors}
                    showSubheading={false} hasInputLabel={false} isOtherLabel={false}
                    handleCommentChange={props.handleCommentChange}
                    valueChangeHandler={props.handleTotalExpense} isRequiredField={false} />
                }
              }
              )
            }
          </tbody>
        </table>
      }
    </>
  );
}
