import React from 'react';
import Asterisk from '../common/Asterisk/Asterisk';
import Input from '../common/Input/Input';
import "./CommercialUnit.css";
import { FormError, getFormErrorByName, getFormErrorMessageByName, hasError, useFormErrorContext } from '../../utils/context/FormErrorContext';

interface CommercialUnitInputFieldProps {
    formData: Record<string, unknown>,
    name: string,
    type?: string,
    errors: FormError[],
    heading: string,
    title?: string,
    disabled?: boolean,
}

export default function CommercialUnitInputField(props: CommercialUnitInputFieldProps): JSX.Element {

    const className = hasError(props.errors, props.name) ? "unit-input table-error" : "border unit-input"
    const { removeError } = useFormErrorContext()
    return (
        <>
            <tr>
                <td className='label-cell'>
                    {props.heading} <Asterisk />
                </td>
            </tr>
            <tr>
                <td>
                    <Input type={props.type === "number" ? "number" : "text"} id={props.name} name={props.name}
                        value={(typeof props.formData[props.name]) === "number" ? (props.formData[props.name] as number).toString() : props.formData[props.name] as string | undefined}
                        className={className}
                        title={props.title}
                        errorMessage={getFormErrorMessageByName(props.errors, props.name)}
                        hideErrorCross={true}
                        noTimer={true}
                        onChange={() => removeError(getFormErrorByName(props.errors, props.name)?.primaryKey as string)}
                        ariaLabel={props.heading}
                        positiveOnly={props.type === "number" ? true : false}
                        disabled={props.disabled}
                    />
                </td>
            </tr>
        </>
    );
}
