import { PUT_INCOME_UNIT_BY_ID, RESET_PUT_INCOME_UNIT } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface updateUnitState extends BaseAsyncReducerState {
    success: boolean,
    message: string,
    result: FormError[]
}
  
const initialState: updateUnitState = {
    success: false,
    message: "",
    loading: false,
    error: false,
    result: []
}

function updateUnitReducer(state = initialState, action: AppAction | LocalAction) {
    if (action.type === RESET_PUT_INCOME_UNIT) {
        return initialState;
    } 

    const appAction = action as AppAction
    const { payload } = appAction


    if(appAction.type === PUT_INCOME_UNIT_BY_ID.SUCCESS && (payload.status === 400 || payload.status === 500)) {
        return {
            ...state,
            ...payload,
            loading: false,
            success: false,
            error: true,
        }
    }

    return defaultAsyncReducer(state, action as AppAction, PUT_INCOME_UNIT_BY_ID)
    
}

export default updateUnitReducer;