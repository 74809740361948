import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReadOnly } from '../../utils/ReadOnlyContext';
import Asterisk from '../common/Asterisk/Asterisk';
import CurrencyInput from '../common/CurrencyInput/CurrencyInput';
import "./CommercialUnit.css";
import { FormError, getFormErrorMessageByName, hasError } from '../../utils/context/FormErrorContext';

interface RateProps {
    formData: Record<string, unknown>,
    handleCurrencyChange: (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => void,
    currencySymbol?: string,
    errors: FormError[],
    disabled?: boolean
}

export default function OperatingExpense(props: RateProps): JSX.Element {
    const { isReadOnly } = useReadOnly()
    const { t } = useTranslation('unit');

    const cbrClassName = hasError(props.errors, "currentBaseRent") ? "table-error" : ""
    const arClassName = hasError(props.errors, "annualRent") ? "table-error" : ""
    const propertyTaxRecoveryClassName = hasError(props.errors, "propertyTaxRecovery") ? "table-error" : ""
    const operatingRecoveryClassName = hasError(props.errors, "operatingRecovery") ? "table-error" : ""
    const tenantImprovementClassName = hasError(props.errors, "tenantImprovement") ? "table-error" : ""


    return (
        <tbody>
            <tr>
                <td className='label-cell padding-left-10 padding-right-10'>
                    {t('headings.annual-rent')} <Asterisk />
                </td>
                <td className={'currency-input-td ' + arClassName}>
                    <CurrencyInput id="annualRent"
                        value={props.formData.annualRent as number | undefined}
                        onChange={props.handleCurrencyChange}
                        title={t('tooltips.annual-rent-tooltip')}
                        errormessage={getFormErrorMessageByName(props.errors, "annualRent")}
                        disabled={isReadOnly || props.disabled}
                    />
                </td>
            </tr>
            <tr>
                <td className='label-cell padding-left-10 padding-right-10'>
                    {t('headings.current-base-rent')}  <Asterisk />
                </td>
                <td className={'currency-input-td ' + cbrClassName}>
                    <CurrencyInput id="currentBaseRent"
                        value={props.formData.currentBaseRent as number | undefined}
                        onChange={props.handleCurrencyChange}
                        symbol={props.currencySymbol}
                        title={t('tooltips.current-base-rent-tooltip')}
                        errormessage={getFormErrorMessageByName(props.errors, "currentBaseRent")}
                        disabled={isReadOnly || props.disabled}
                    />
                </td>
            </tr>
            <tr>
                <td className='label-cell padding-left-10 padding-right-10'>
                    {t('headings.prop-tax-recoveries')}
                </td>
                <td className={propertyTaxRecoveryClassName}>
                    <CurrencyInput id="propertyTaxRecovery"
                        value={props.formData.propertyTaxRecovery as number | undefined}
                        onChange={props.handleCurrencyChange}
                        symbol={props.currencySymbol}
                        title={t('tooltips.prop-tax-recoveries-tooltip')}
                        errormessage={getFormErrorMessageByName(props.errors, "propertyTaxRecovery")}
                        disabled={isReadOnly || props.disabled}
                    />
                </td>
            </tr>
            <tr>
                <td className='label-cell padding-left-10 padding-right-10 no-wrap'>
                    {t('headings.operating-recoveries')}
                </td>
                <td className={operatingRecoveryClassName}>
                    <CurrencyInput id="operatingRecovery"
                        value={props.formData.operatingRecovery as number | undefined}
                        onChange={props.handleCurrencyChange}
                        symbol={props.currencySymbol}
                        title={t('tooltips.operating-recoveries-tooltip')}
                        errormessage={getFormErrorMessageByName(props.errors, "operatingRecovery")}
                        disabled={isReadOnly || props.disabled}
                    />
                </td>
            </tr>
            <tr>
                <td className='label-cell padding-left-10 padding-right-10'>
                    {t('headings.tenant-improvements')}
                </td>
                <td className={tenantImprovementClassName}>
                    <CurrencyInput id="tenantImprovement"
                        value={props.formData.tenantImprovement as number | undefined}
                        onChange={props.handleCurrencyChange}
                        symbol={props.currencySymbol}
                        title={t('tooltips.tenant-improvements-tooltip')}
                        errormessage={getFormErrorMessageByName(props.errors, "tenantImprovement")}
                        disabled={isReadOnly || props.disabled}
                    />
                </td>
            </tr>
        </tbody>
    );
}
