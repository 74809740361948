import { FormError } from "../../../utils/context/FormErrorContext";
import { POST_INCOME_UNIT, RESET_POST_INCOME_UNIT } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface createUnitState extends BaseAsyncReducerState {
    success: boolean,
    message: string,
    result: FormError[]
}
  
const initialState: createUnitState = {
    success: false,
    message: "",
    loading: false,
    error: false,
    result: []
}

function createUnitReducer(state = initialState, action: AppAction | LocalAction) {
    

    if (action.type === RESET_POST_INCOME_UNIT) {
        return initialState;
    } 
    
    const appAction = action as AppAction
    const { payload } = appAction


    if(appAction.type === POST_INCOME_UNIT.SUCCESS && (payload.status === 400 || payload.status === 500)) {
        return {
            ...state,
            ...payload,
            loading: false,
            success: false,
            error: true,
        }
    }

    return defaultAsyncReducer(state, action as AppAction, POST_INCOME_UNIT)
}

export default createUnitReducer;