import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import PortalMenu from "../../components/PortalMenu/PortalMenu";
import CompiledComponents from "../../utils/CompiledComponents";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";

import { RootState } from "../../redux/reducers/root";
import MenuState from "../../types/menu/MenuState";
import { GetElsLookupsState } from "../../redux/reducers/getLookups/getElsLookupsReducer";
import { getElsLookups } from "../../redux/actions/lookups/lookups";
import { Option } from '../../components/common/Dropdown/Dropdown';
import './Portal.css';
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import { GlobalSettingState } from "../../redux/reducers/getGlobalSetting/getGlobalSettingReducer";
import { getGlobalSetting } from "../../redux/actions/global/global";
import { ReadOnlyProvider } from "../../utils/ReadOnlyContext";
import { ReadOnlyState } from "../../redux/reducers/getReadOnly/readOnlyReducer";
import { SaveDataState } from "../../redux/reducers/getSaveData/saveDataReducer";
import { LoadingState } from "../../redux/reducers/getLoadingReducer/getLoadingReducer";
import { getProfileDetails } from "../../redux/actions/profile/profile";
import resetOnRefresh from "../../redux/reducers/getLoadingReducer/resetOnRefreshReducer";
import { ProfileOverviewState } from "../../redux/reducers/getProfile/getProfileOverviewReducer";

function Portal(): JSX.Element {
  const dispatch = useDispatch();
  const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
  const readOnlyState: ReadOnlyState = useSelector((state: RootState) => state.readOnlyReducer.readOnly)
  const [DynamicComponent, setDynamicComponent] = useState<JSX.Element>();
  const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
  const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
  const loadingState: LoadingState = useSelector((state: RootState) => state.loadingReducer.getLoading);
  const isInternalUser = localStorage.getItem("isInternalUser") ? true : false;
  const snapshotId = localStorage.getItem("currentSnapshotId")
  const propertyCode = localStorage.getItem("propertyCode") as string

  useEffect(() => {
    if (!globalSettingState.loading && !globalSettingState.success) {
      dispatch(getGlobalSetting());
    }
  }, [dispatch, globalSettingState.loading, globalSettingState.success]);

  useEffect(() => {
    if (menuState.activeItem.componentName) {
      const findById = document.getElementById("portal-content");
      if (findById) {
        findById.scrollIntoView()
      }
      setDynamicComponent(CompiledComponents[menuState.activeItem.componentName]);
    }
  }, [menuState.activeItem.componentName])

  useEffect(() => {
    if (elsLookupsState.success && 'dropdowns' in elsLookupsState && Object.keys(elsLookupsState.dropdowns).length > 0) {
      const sortedUnitSubtype1Dropdown =
        (elsLookupsState.dropdowns["unitSubType1"] as Record<string, unknown>[]).sort((a, b) =>
          ((a["unitSubtypePosition"] as number) > (b["unitSubtypePosition"] as number)) ? 1 :
            (((b["unitSubtypePosition"] as number) > (a["unitSubtypePosition"] as number)) ? -1 : 0));
      const sortedUnitSubtype1Options: Option[] = [{ value: "", text: "" }];
      sortedUnitSubtype1Dropdown.forEach((option) => {
        if (option["activeFlag"] === "Y") {
          sortedUnitSubtype1Options.push({ value: option["unitSubtypeCode"], text: option["unitSubtypeDescription"] });
        }
      });
      elsLookupsState.dropdowns["unitSubtypeCodeOptions"] = sortedUnitSubtype1Options;

      const sortedUnitLeaseStatusDropdown =
        (elsLookupsState.dropdowns["unitLeaseStatus"] as Record<string, unknown>[]).sort((a, b) =>
          ((a["unitLeaseStatusPosition"] as number) > (b["unitLeaseStatusPosition"] as number)) ? 1 :
            (((b["unitLeaseStatusPosition"] as number) > (a["unitLeaseStatusPosition"] as number)) ? -1 : 0));
      const sortedUnitLeaseStatusOptions: Option[] = [{ value: "", text: "" }];
      sortedUnitLeaseStatusDropdown.forEach((option) => {
        if (option["activeFlag"] === "Y") {
          sortedUnitLeaseStatusOptions.push({ value: option["unitLeaseStatusCode"], text: option["unitLeaseStatusDescription"]});
        }
      });
      elsLookupsState.dropdowns["leaseStatusCodeOptions"] = sortedUnitLeaseStatusOptions;

      const sortedUnitLeaseTypeDropdown =
        (elsLookupsState.dropdowns["unitLeaseType"] as Record<string, unknown>[]).sort((a, b) =>
          ((a["unitLeaseTypePosition"] as number) > (b["unitLeaseTypePosition"] as number)) ? 1 :
            (((b["unitLeaseTypePosition"] as number) > (a["unitLeaseTypePosition"] as number)) ? -1 : 0));
      const sortedUnitLeaseTypeOptions: Option[] = [{ value: "", text: "" }];
      sortedUnitLeaseTypeDropdown.forEach((option) => {
        if (option["activeFlag"] === "Y") {
          sortedUnitLeaseTypeOptions.push({ value: option["unitLeaseTypeCode"], text: option["unitLeaseTypeDescription"] });
        }
      });
      elsLookupsState.dropdowns["leaseTypeCodeOptions"] = sortedUnitLeaseTypeOptions;

      if ('officeFloorLevel' in elsLookupsState.dropdowns || 'nonOfficeFloorLevel' in elsLookupsState.dropdowns) {
        const sortedUnitLevelOptions: Option[] = [{ value: "", text: "" }];
        if (['402', '403'].includes(propertyCode)) {
          (elsLookupsState.dropdowns["officeFloorLevel"] && elsLookupsState.dropdowns["officeFloorLevel"] as Record<string, unknown>[]).forEach((option) => {
            if (option["activeFlag"] === "Y") {
              sortedUnitLevelOptions.push({ value: option["unitLevelCode"], text: option["unitLevelDescription"]});
            }
          });
        }
        else {
          (elsLookupsState.dropdowns["nonOfficeFloorLevel"] && elsLookupsState.dropdowns["nonOfficeFloorLevel"]).forEach((option) => {
            if (option["activeFlag"] === "Y") {
              sortedUnitLevelOptions.push({ value: option["unitLevelCode"], text: option["unitLevelDescription"]});
            }
          });
        }
        elsLookupsState.dropdowns["floorLevelOptions"] = sortedUnitLevelOptions;
      }

      if ('tenancyType' in elsLookupsState.dropdowns) {
        const sortedUnitLevelOptions: Option[] = [{ value: "", text: "" }];
        (elsLookupsState.dropdowns["tenancyType"] as Record<string, unknown>[]).forEach((option) => {
          if (option["activeFlag"] === "Y") {
            sortedUnitLevelOptions.push({ value: option["tenancyTypeCode"], text: option["tenancyTypeDescription"] });
          }
        });
        elsLookupsState.dropdowns["tenancyTypeCodeOptions"] = sortedUnitLevelOptions;
      }

      if ('designType' in elsLookupsState.dropdowns) {
        const sortedUnitLevelOptions: Option[] = [{ value: "", text: "" }];
        (elsLookupsState.dropdowns["designType"] as Record<string, unknown>[]).forEach((option) => {
          if (option["activeFlag"] === "Y") {
            sortedUnitLevelOptions.push({ value: option["unitDesignTypeCode"], text: option["unitDesignTypeDescription"] });
          }
        });
        elsLookupsState.dropdowns["designTypeCodeOptions"] = sortedUnitLevelOptions;
      }

      if ('unitVariable' in elsLookupsState.dropdowns) {
        const sortedUnitLevelOptions: Option[] = [{ value: "", text: "" }];
        (elsLookupsState.dropdowns["unitVariable"] as Record<string, unknown>[]).forEach((option) => {
          if (option["activeFlag"] === "Y") {
            sortedUnitLevelOptions.push({ value: option["unitVariableCode"], text: option["unitVariableDescription"] });
          }
        });
        elsLookupsState.dropdowns["variablesOptions"] = sortedUnitLevelOptions;
      }
    } else if (!elsLookupsState.loading && !elsLookupsState.success && profileOverview.profile.profile_type_code !== "GEV") { // disable ELS calls for GEV portfolio
      dispatch(getElsLookups());
    }
  }, [dispatch, elsLookupsState, profileOverview.profile.profile_type_code, propertyCode]);

  useEffect(() => {
    if (snapshotId) {
      dispatch(getProfileDetails(snapshotId))
    }
  }, [snapshotId, dispatch])

  useEffect(() => {
    const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
      e.preventDefault();
      if (loadingState.loading < 0) {
        dispatch(resetOnRefresh())
      }
      e.returnValue = "";
    }
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [dispatch, loadingState.loading]);

  const openSideBar = "/images/layout-sidebar-left-expand.svg"
  const closeSideBar = "/images/layout-sidebar-left-collapse.svg"

  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [portalLayoutClassName, setPortalLayoutClassName] = useState('portal-root-layout sidebar-open')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [toggleSidebarIcon, setToggleSidebarIcon] = useState(closeSideBar)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  useEffect(() => {
    const sidebarClassName = isSidebarOpen ? 'sidebar-open' : 'sidebar-close'
    const portalLayoutClassName = 'portal-root-layout ' + sidebarClassName
    setPortalLayoutClassName(portalLayoutClassName)
    setToggleSidebarIcon(isSidebarOpen ? closeSideBar : openSideBar)
  }, [isSidebarOpen])

  return (
    <LoadingSpinner loading={globalSettingState.loading || saveData.saving || loadingState.loading !== 0}>
      <div className={portalLayoutClassName}>
        <ReadOnlyProvider isInitiallyReadOnly={readOnlyState.isReadOnly}>
          <div className="portal-root-body">

            <div className="portal-sidebar">
              <PortalMenu />
            </div>

            <div className={'portal-root-content'}>
              <div className={'portal-header'}>
                <div className="">
                  <Header showAutoSave={isInternalUser ? false : true}
                    isInternalUser={isInternalUser} />
                </div>
              </div>
              <div className="portal-breadcrumb">
                {/* <img src={toggleSidebarIcon}
                  onClick={() => toggleSidebar()}
                  alt="toggleSidebar" /> */}
                <Breadcrumb />
              </div>
              <div className={'portal-content'} id={'portal-content'}>
                {menuState.activeItem.componentName && DynamicComponent}
              </div>
            </div>

          </div>
          <div className={'portal-root-footer'}>
            <div className={'portal-footer'}>
              <Footer />
            </div>
          </div>
        </ReadOnlyProvider>
      </div>
    </LoadingSpinner>
  );
}

export default Portal;
