import React, { useEffect, useState } from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import Checkbox from '../common/Checkbox/Checkbox';
import Button from '../common/Button/Button';
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface NotExpiredLeaseWizardProps {
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    leaseStatusLookup: Record<string, unknown>;
    leaseTypeLookup: Record<string, unknown>;
    confirmedArr: number[];
    setConfirmedArr: (value: number[]) => void;
}

export default function NotExpiredLeaseWizard(props: NotExpiredLeaseWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredIndices, setFilteredIndices] = useState<number[]>([]);
    const [sort, setSort] = useState("");

    const checkboxEventHandler = (e: React.ChangeEvent<Element>, index?: number) => {
        let newConfirmedArr = [...props.confirmedArr];
        if (index !== undefined) {
            const indexOf = newConfirmedArr.indexOf(index);
            if ((e.target as HTMLInputElement).checked) {
                if (indexOf < 0) {
                    newConfirmedArr.push(index);
                }
            } else {
                if (indexOf >= 0) {
                    newConfirmedArr.splice(indexOf, 1);
                }
            }
        } else {
            if (newConfirmedArr.length === filteredIndices.length) {
                newConfirmedArr = [];
            } else {
                newConfirmedArr = filteredIndices;
            }
        }
        props.setConfirmedArr(newConfirmedArr.sort());
    }

    useEffect(() => {
        const indices = props.tenantList.map((_, index) => index);
        const filteredIndiceArr = indices.filter((index) => ((props.tenantList[index]["endDate"] && new Date(props.tenantList[index]["endDate"] as string) >=  new Date()) || props.tenantList[index]["leaseStatusCode"] === "1" || props.tenantList[index]["leaseStatusCode"] === "5"));
        if (filteredIndiceArr.length > 10) {
            setTotalPages(Math.ceil(filteredIndiceArr.length / 10));
        }
        setFilteredIndices(filteredIndiceArr);
        // eslint-disable-next-line
    }, []);

    const sortTable = (sortCriteria: string, isNumber = false, descriptionLookup?: Record<string, unknown>) => {
        let order = ""
        const newFilteredIndice = [...filteredIndices];
        if (sort === sortCriteria + "Asc") {
            order = "Des";
        } else {
            order = "Asc";
        }
        setSort(sortCriteria + order);
        newFilteredIndice.sort((a, b) => {
            let compareResult = 0;
            if (isNumber) {
                compareResult = (props.tenantList[a][sortCriteria] as number) - (props.tenantList[b][sortCriteria] as number);
            } else {
                const aValue = (descriptionLookup ? descriptionLookup[props.tenantList[a][sortCriteria] as string] as string : props.tenantList[a][sortCriteria] as string) ?? ""
                const bValue = (descriptionLookup ? descriptionLookup[props.tenantList[b][sortCriteria] as string] as string : props.tenantList[b][sortCriteria] as string) ?? ""
                compareResult = aValue.localeCompare(bValue);
            }
            if (order === "Des") {
                compareResult = compareResult / -1;
            }
            return compareResult
        })
        setFilteredIndices(newFilteredIndice);
    }

    const headings = [<th className='small-column' key="not-expired-lease-wizard-heading-1">
            <Button className='guided-wizard-sort-button' text={<><span>{t("unit-heading.floor-level") + " "}</span>{sort.substring(0, sort.length - 3) === "floorLevel" && <FontAwesomeIcon icon={sort === "floorLevelAsc" ? faChevronUp : faChevronDown} />}</>} 
                onClick={() => sortTable("floorLevel", false, props.floorDescriptionLookup)}/>
        </th>,
        <th key="not-expired-lease-wizard-heading-2">
            <Button className='guided-wizard-sort-button' text={<><span>{t("unit-heading.tenant-name") + " "}</span>{sort.substring(0, sort.length - 3) === "tenantName" && <FontAwesomeIcon icon={sort === "tenantNameAsc" ? faChevronUp : faChevronDown} />}</>} 
                onClick={() => sortTable("tenantName")}/>
        </th>,
        <th key="not-expired-lease-wizard-heading-3">
            <Button className='guided-wizard-sort-button' text={<><span>{t("unit-heading.unit-number") + " "}</span>{sort.substring(0, sort.length - 3) === "tenantUnitNumber" && <FontAwesomeIcon icon={sort === "tenantUnitNumberAsc" ? faChevronUp : faChevronDown} />}</>} 
                onClick={() => sortTable("tenantUnitNumber")}/></th>,
        <th key="not-expired-lease-wizard-heading-4">
            <Button className='guided-wizard-sort-button' text={<><span>{t("unit-heading.gla") + " "}</span>{sort.substring(0, sort.length - 3) === "netLeasableArea" && <FontAwesomeIcon icon={sort === "netLeasableAreaAsc" ? faChevronUp : faChevronDown} />}</>} 
                onClick={() => sortTable("netLeasableArea", true)}/></th>,
        <th key="not-expired-lease-wizard-heading-5">
            <Button className='guided-wizard-sort-button' text={<><span>{t("unit-heading.lease-status") + " "}</span>{sort.substring(0, sort.length - 3) === "leaseStatusCode" && <FontAwesomeIcon icon={sort === "leaseStatusCodeAsc" ? faChevronUp : faChevronDown} />}</>} 
                onClick={() => sortTable("leaseStatusCode", false, props.leaseStatusLookup)}/></th>,
        <th key="not-expired-lease-wizard-heading-6">
            <Button className='guided-wizard-sort-button' text={<><span>{t("unit-heading.lease-type") + " "}</span>{sort.substring(0, sort.length - 3) === "leaseTypeCode" && <FontAwesomeIcon icon={sort === "leaseTypeCodeAsc" ? faChevronUp : faChevronDown} />}</>} 
                onClick={() => sortTable("leaseTypeCode", false, props.leaseTypeLookup)}/></th>,
        <th key="not-expired-lease-wizard-heading-7">
            <Button className='guided-wizard-sort-button' text={<><span>{t("unit-heading.negotiated-date") + " "}</span>{sort.substring(0, sort.length - 3) === "negotiatedDate" && <FontAwesomeIcon icon={sort === "negotiatedDateAsc" ? faChevronUp : faChevronDown} />}</>} 
                onClick={() => sortTable("negotiatedDate")}/></th>,
        <th key="not-expired-lease-wizard-heading-8">
            <Button className='guided-wizard-sort-button' text={<><span>{t("unit-heading.start-date") + " "}</span>{sort.substring(0, sort.length - 3) === "startDate" && <FontAwesomeIcon icon={sort === "startDateAsc" ? faChevronUp : faChevronDown} />}</>} 
                onClick={() => sortTable("startDate")}/></th>,
        <th key="not-expired-lease-wizard-heading-9">
            <Button className='guided-wizard-sort-button' text={<><span>{t("unit-heading.end-date") + " "}</span>{sort.substring(0, sort.length - 3) === "endDate" && <FontAwesomeIcon icon={sort === "endDateAsc" ? faChevronUp : faChevronDown} />}</>} 
                onClick={() => sortTable("endDate")}/></th>,
        <th key="not-expired-lease-wizard-heading-10">
            <Button className='guided-wizard-sort-button' text={<><span>{t("unit-heading.current-rent") + " "}</span>{sort.substring(0, sort.length - 3) === "currentBaseRent" && <FontAwesomeIcon icon={sort === "currentBaseRentAsc" ? faChevronUp : faChevronDown} />}</>} 
                onClick={() => sortTable("currentBaseRent", true)}/></th>,
        <th key="not-expired-lease-wizard-heading-11"><div><label htmlFor='not-expired-lease-wizard-all'>{t("confirm")}</label></div>
            <div><Checkbox name="not-expired-lease-wizard-all" id="not-expired-lease-wizard-all"
            checked={props.confirmedArr.length === filteredIndices.length} onChange={(e) => checkboxEventHandler(e)}/></div>
        </th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (currentPage - 1); index < Math.min(filteredIndices.length, 10 * currentPage); index++) {
                const realIndex = filteredIndices[index];
                tableBody.push (<tr key={`not-expired-lease-wizard-row-${realIndex}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[realIndex]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[realIndex]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[realIndex]["tenantUnitNumber"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["netLeasableArea"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.leaseStatusLookup[props.tenantList[realIndex]["leaseStatusCode"] as string] as string}</p></td>
                    <td className='no-input-cell'><p>{props.leaseTypeLookup[props.tenantList[realIndex]["leaseTypeCode"] as string] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["negotiatedDate"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["startDate"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["endDate"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.tenantList[realIndex]["currentBaseRent"] as string}</p></td>
                    <td className='center-cell'>
                        <Checkbox key={`not-expired-lease-wizard-${realIndex}`} name={`not-expired-lease-wizard-${realIndex}`} id={`not-expired-lease-wizard-${realIndex}`}
                            checked={props.confirmedArr.includes(realIndex)} onChange={(e) => checkboxEventHandler(e, realIndex)}/>
                    </td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <p>{t("not-expired-lease-wizard.not-expired-lease-description")}</p>
        <DataTable headings={headings} className='wizard-table' totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage}>
            {buildTableContent()}
        </DataTable>
    </div>)
}