import { faBuilding, faCartShopping, faFileInvoiceDollar, faFilePdf, faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons"
import MenuItem from "../../../types/menu/MenuItem"

const LLENavList: MenuItem[] = [
  {
    id: 1,
    name: 'contactInfo',
    componentName: 'ContactInfo',
    complete: false,
  },
  {
    id: 2,
    name: 'incomeExpense',
    componentName: 'IncomeExpense',
    complete: false,
    icon: faMoneyBillTrendUp,
    subItems: [
      {
        id: 21,
        name: 'numberOfSites',
        complete: false,
        componentName: 'NumberOfSites'
      },
      {
        id: 22,
        name: 'incomeRevenue',
        complete: false,
        componentName: 'RevenueContainer',
      },
      {
        id: 23,
        name: 'expenses',
        hasTabs: true,
        complete: false,
        componentName: 'ExpenseContainer',
        subItems: [
          {
            id: 231,
            name: 'general',
            complete: false,
          },
          {
            id: 232,
            name: 'admin',
            complete: false,
          },
          {
            id: 233,
            name: 'utilities',
            complete: false,
          },
          {
            id: 234,
            name: 'propMaintenance',
            complete: false,
          },
          {
            id: 235,
            name: 'other',
            complete: false,
          }
        ]
      },
      {
        id: 24,
        name: 'rentalLoss',
        complete: false,
        componentName: 'IncomeAttributeTableNoTotal'
      },
      {
        id: 25,
        name: 'capitalExpSummary',
        complete: false,
        componentName: 'CapitalCostsSummary'
      },
      {
        id: 26,
        name: 'comments',
        complete: false,
        componentName: 'Comment',
      },
    ],
  },
  {
    id: 3,
    name: 'residential',
    complete: false,
    icon: faBuilding,
    subItems: [
      {
        id: 31,
        name: 'suiteBreakdownTable',
        complete: false,
        componentName: 'SuiteBreakdown'
      }
    ]
  },
  {
    id: 4,
    name: 'commercial',
    complete: false,
    icon: faCartShopping,
    subItems: [
      {
        id: 41,
        name: 'leaseInformationTable',
        componentName: 'LeaseInfo',
        complete: false
      },
      {
        id: 42,
        name: 'vacantAskingRate',
        complete: false,
        componentName: 'VacantAskingRate',
      },
      {
        id: 43,
        name: 'parkingSummary',
        complete: false,
        componentName: 'ParkingPropertySummary',
      }
    ]
  },
  {
    id: 5,
    name: 'summaryReview',
    complete: false,
    componentName: 'Summary',
    icon: faFilePdf
  },
  {
    id: 6,
    name: 'submit',
    complete: false,
    icon: faFileInvoiceDollar,
    componentName: 'Submit'
  }
]

export default LLENavList