import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { RootState } from '../../redux/reducers/root';
import CommercialUnitType from '../../types/unit/CommercialUnit';
import Button from '../common/Button/Button';
import CommercialUnitDatePickerField from './CommercialUnitDatePickerField';
import CommercialUnitDropdownField from './CommercialUnitDropdownField';
import CommercialUnitInputField from './CommercialUnitInputField';
import OperatingExpense from './OperatingExpense';
import Rate from './Rate';
import StepDate from './StepDate';
import CommercialUnitInternalTable from './CommercialUnitInternalTable';
// import { validate, validateField, validateToastNet, validateToastVacant } from './CommercialUnitValidator';
import "./CommercialUnit.css";
import { GlobalSettingState } from '../../redux/reducers/getGlobalSetting/getGlobalSettingReducer';
import { useReadOnly } from '../../utils/ReadOnlyContext';
import Checkbox from '../common/Checkbox/Checkbox';
import Input from '../common/Input/Input';
import { getFormErrorByName, getFormErrorMessageByName, hasError, useFormErrorContext } from '../../utils/context/FormErrorContext';
import ErrorMessages from '../common/ErrorMessages/ErrorMessages';


interface CommercialUnitProps {
    unit: CommercialUnitType,
    onSave: (unit: Record<string, unknown>) => void,
    onCancel: () => void,
    showDeleteBtn: boolean,
    tenantSummaryType: "1" | "2"
}

export default function CommercialUnit(props: CommercialUnitProps): JSX.Element {
    const { i18n } = useTranslation();
    const { t } = useTranslation(['unit', 'common']);
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
    const isInternalUser = localStorage.getItem('isInternalUser') ? true : false;
    const currencySymbol = (globalSettingState.setting["currency"] as Record<string, unknown>)["symbol"];
    const currencyPerSF = currencySymbol + t('headings.perSF');
    // const [changeField, setChangeField] = useState<string | null>(null);
    const [changedFields, setChangedFields] = useState<Array<string>>([]);
    const { isReadOnly } = useReadOnly()
    const { errors, removeError } = useFormErrorContext();


    const freeRentMonthClassName = hasError(errors, "freeRentMonth") ? "text-align-right table-error" : "text-align-right"

    const initData = () => {
        const data = {} as Record<string, unknown>;
        for (const [key, value] of Object.entries(props.unit)) {
            if (value == null) {
                data[key] = undefined;
            } else {
                data[key] = value;
            }
        }
        data['init'] = true;
        return data;
    }

    const [formData, setFormData] = useState<Record<string, unknown>>(initData());

    // const [errors, setErrors] = useState<Record<string, string>>({
    //     tenantName: "",
    //     unitSubtypeCode: "",
    //     leaseStatusCode: "",
    //     startDate: "",
    //     leaseTypeCode: "",
    //     netLeasableArea: "",
    //     tenantUnitNumber: "",
    //     floorLevel: "",
    //     negotiatedDate: "",
    //     endDate: "",
    //     annualRent: "",
    //     currentBaseRent: "",
    //     propertyTaxRecovery: "",
    //     operatingRecovery: "",
    //     freeRentMonth: "",
    //     tenantImprovement: "",
    //     stepUpRate1: "",
    //     stepUpRate2: "",
    //     stepUpRate3: "",
    //     stepUpRate4: "",
    //     variables: ""
    // });

    // const hasError = (errors: Record<string, string>) => {
    //     let hasError = false;
    //     for (const errorKey in errors) {
    //         if (errors[errorKey].length > 0) {
    //             hasError = true;
    //             break;
    //         }
    //     }
    //     return hasError;
    // };

    const numberFields = ["netLeasableArea", "freeRentMonth"];
    const checkFields = [
        "includeInsuranceFlag",
        "includeManagementFlag",
        "includeMaintenanceFlag",
        "includeHydroFlag",
        "includeWaterFlag",
        "includeHvacFlag",
        "linkFlag",
        "landLeaseFlag"
    ];
    const currencyFields = [
        "annualRent",
        "currentBaseRent",
        "propertyTaxRecovery",
        "operatingRecovery",
        "tenantImprovement",
        "stepUpRate1",
        "stepUpRate2",
        "stepUpRate3",
        "stepUpRate4",
    ];

    const saveChangedField = (name: string) => {
        if (!changedFields.includes(name)) {
            setChangedFields([...changedFields, name])
        }
    }

    const handleDateChange = (date: Date | null | undefined, name: string) => {
        if (typeof name !== "string") {
            return;
        }

        let fd = {};
        if (date) {
            const dateStr = format(date, "yyyy-MM-dd")
            fd = { ...formData, [name]: dateStr };
        } else {
            fd = { ...formData, [name]: undefined };
        }
        setFormData(fd);
        // setChangeField(name);
        saveChangedField(name);
    };

    const handleCurrencyChange = (value: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
        setFormData({ ...formData, [name]: value });
        // setChangeField(name);
        saveChangedField(name);
    };

    const formChanged = (e: React.FormEvent<HTMLFormElement>) => {
        const target = e.target as HTMLInputElement;
        const targetName = target.name;
        if (!currencyFields.includes(targetName)) {
            const value = target.value.trim();
            if (targetName === "variables") {
                const selectedOptions = (e.target as HTMLSelectElement).selectedOptions;
                const values = Array.from(selectedOptions, option => ({
                    code: option.value,
                    description: option.text
                }));
                setFormData({ ...formData, [targetName]: values });
            } else if (checkFields.includes(targetName)) {
                setFormData({ ...formData, [targetName]: target.checked });
            } else if (numberFields.includes(targetName)) {
                if (value === "") {
                    setFormData({ ...formData, [targetName]: undefined });
                } else {
                    setFormData({ ...formData, [targetName]: Number(value) });
                }
            } else if (targetName === "leaseStatusCode" && value === "1") { // "1" is Vacant
                removeError(getFormErrorByName(errors, "tenantName")?.primaryKey as string)
                removeError(getFormErrorByName(errors, "endDate")?.primaryKey as string)
                removeError(getFormErrorByName(errors, "currentBaseRent")?.primaryKey as string)
                removeError(getFormErrorByName(errors, "annualRent")?.primaryKey as string)
                removeError(getFormErrorByName(errors, "freeRentMonth")?.primaryKey as string)
                removeError(getFormErrorByName(errors, "leaseTypeCode")?.primaryKey as string)

                const updatedData = {
                    ...formData,
                    tenantName: "Vacant",
                    startDate: null, // If Lease Status = Vacant, blank out both Start and End Date
                    endDate: null, // If Lease Status = Vacant, blank out both Start and End Date
                    [targetName]: value
                };
                setFormData(updatedData);
                setChangedFields([...changedFields, "tenantName", "startDate", "endDate"]);
            } else if (targetName === "leaseTypeCode" && value === "1") {
                const updatedData = {
                    ...formData,
                    includeInsuranceFlag: false,
                    includeManagementFlag: false,
                    includeMaintenanceFlag: false,
                    includeHydroFlag: false,
                    includeWaterFlag: false,
                    includeHvacFlag: false,
                    [targetName]: value
                };
                setFormData(updatedData);
                setChangedFields([...changedFields, "includeInsuranceFlag", "includeManagementFlag", "includeMaintenanceFlag", "includeHydroFlag", "includeWaterFlag", "includeHvacFlag"]);
            } else if (targetName === "leaseTypeCode" && value === "2") {
                const updatedData = {
                    ...formData,
                    includeInsuranceFlag: true,
                    includeManagementFlag: true,
                    includeMaintenanceFlag: true,
                    includeHydroFlag: true,
                    includeWaterFlag: true,
                    includeHvacFlag: true,
                    [targetName]: value
                };
                setFormData(updatedData);
                setChangedFields([...changedFields, "includeInsuranceFlag", "includeManagementFlag", "includeMaintenanceFlag", "includeHydroFlag", "includeWaterFlag", "includeHvacFlag"]);
            } else {
                if (value === "") {
                    setFormData({ ...formData, [targetName]: undefined });
                } else {
                    setFormData({ ...formData, [targetName]: value });
                }
            }
            // setChangeField(targetName);
            saveChangedField(targetName);
        }
    }

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        //const validateErrors = validate(formData, errors, t);
        //setErrors(validateErrors);
        // if (hasError(validateErrors)) {
        //     toast.error(t("toastMessage.validationFailed") as string);
        // } else {
            let result = props.unit;
            result.unitTypeCode = props.tenantSummaryType



            if (changedFields.includes("analysisFlag")) {
                const analysisReviewedBy = localStorage.getItem("username") ?? ""
                const analysisReviewedDate = (new Date().toISOString()) ?? null
                result.analysisReviewedBy = analysisReviewedBy
                result.analysisReviewedDate = analysisReviewedDate
            }

            changedFields.forEach((field) => {
                const value = formData[field];
                if (value) {
                    result = { ...result, [field]: value };
                } else {
                    result = { ...result, [field]: null };
                }
            });

            if(result.leaseStatusCode === "1") {
                result.tenantName = "Vacant"
            }

            props.onSave(result as unknown as Record<string, unknown>);
       // }
    }

    // useEffect(() => {
    //     if (changeField) {
    //         const validatedErrors = validateField(changeField, formData, errors, t);
    //         setErrors(validatedErrors);
    //         if (["leaseStatusCode", "startDate"].includes(changeField)) {
    //             validateToastVacant(formData, t);
    //         }
    //         if (["leaseTypeCode", "propertyTaxRecovery", "operatingRecovery"].includes(changeField)) {
    //             validateToastNet(formData, t);
    //         }
    //     } else {
    //         if (!isReadOnly && formData['init']) {
    //             formData['init'] = false;
    //             validateToastVacant(formData, t);
    //             validateToastNet(formData, t);
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [changeField, formData]);

    return (
        <div>
            <form id="unit-form" onSubmit={(e) => submit(e)} onChange={(e) => formChanged(e)}>
                <div className='unit-error-section'>
                    <ErrorMessages errors={errors}></ErrorMessages>
                </div>
                <table className='unit-table no-border-input' role="none">
                    
                    <tbody className='valign-top'>

                        
                    
                        <tr className='unit-root-level-tr'>
                        
                            {isInternalUser &&
                                <td>
                                    <CommercialUnitInternalTable formData={formData} />
                                </td>
                            }
                            <td>
                            
                                <table className='unit-table-section' role="none">
                                    
                                    <tbody>
                                        <CommercialUnitInputField name='tenantUnitNumber' formData={formData} errors={errors}
                                            heading={t('headings.unit-number')} title={t('tooltips.unit-number-tooltip')} />
                                        <CommercialUnitDropdownField name='floorLevel' formData={formData} errors={errors}
                                            heading={t('headings.floor-level')} title={t('tooltips.floor-level-tooltip')} />
                                        <CommercialUnitInputField name='netLeasableArea' formData={formData} errors={errors} type="number"
                                            heading={t('headings.leased-area')} title={t('tooltips.leased-area-tooltip')} />
                                        <CommercialUnitDropdownField name='leaseStatusCode' formData={formData} errors={errors}
                                            heading={t('headings.lease-status')} title={t('tooltips.lease-status-tooltip')} />
                                        <CommercialUnitDropdownField name='leaseTypeCode' formData={formData} errors={errors}
                                            heading={t('headings.lease-type')} title={t('tooltips.lease-type-tooltip')} disabled={formData.leaseStatusCode === '1'} />
                                        <CommercialUnitInputField name='tenantName' formData={formData} errors={errors}
                                            heading={t('headings.tenant-name')} title={t('tooltips.tenant-name-tooltip')} disabled={formData.leaseStatusCode === '1'} />
                                        <CommercialUnitDropdownField name='unitSubtypeCode' formData={formData} errors={errors}
                                            heading={(formData.leaseStatusCode === '1') ? t('headings.former-unit-use') : t('headings.unit-use')} title={t('tooltips.unit-use-tooltip')}
                                            disabled={formData.leaseStatusCode === '1'} />
                                    </tbody>
                                </table>
                            </td>
                            <td>
                                <table className='unit-table-section' role="none">
                                    <tbody>
                                        <CommercialUnitDatePickerField name={'negotiatedDate'} formData={formData} errors={errors}
                                            onChange={handleDateChange} asterisk={false}
                                            heading={t('headings.negotiated-date')} title={t('tooltips.negotiated-date-tooltip')} disabled={formData.leaseStatusCode === '1'} />
                                        <CommercialUnitDatePickerField name={'startDate'} formData={formData} errors={errors}
                                            onChange={handleDateChange} asterisk={true} maxDate={new Date().getFullYear() + "-12-31"}
                                            heading={(formData.leaseStatusCode === '1') ? t('headings.vac-start-date') : t('headings.start-date')} title={t('tooltips.start-date-tooltip')} />
                                        <CommercialUnitDatePickerField name={'endDate'} formData={formData} errors={errors}
                                            onChange={handleDateChange} asterisk={false} minDate={"2019-06-01"}
                                            heading={t('headings.end-date')} title={t('tooltips.end-date-tooltip')} disabled={formData.leaseStatusCode === '1'} />
                                        <tr>
                                            <td className='label-cell'>{t('headings.land-lease')}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <fieldset className='noborder-fieldset'>
                                                    <legend className='invisible-legend'><span>landLeaseFlag</span></legend>
                                                    <div className='land-lease' title={t('tooltips.land-lease-tooltip')}>
                                                        <Checkbox
                                                            onChange={() => removeError(getFormErrorByName(errors, "landLeaseFlag")?.primaryKey as string)}
                                                            name='landLeaseFlag'
                                                            id='landLeaseFlag'
                                                            defaultChecked={formData.landLeaseFlag != null && formData.landLeaseFlag === true}
                                                            disabled={formData.leaseStatusCode === '1'}
                                                        />
                                                    </div>
                                                    <div className='input-error-message'>{getFormErrorMessageByName(errors, "landLeaseFlag")}</div>
                                                </fieldset>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='label-cell' id="select-all-expenses"
                                                title={t('tooltips.enpenses-header-tooltip')}>
                                                {t('headings.enpenses-header')}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table role="none">
                                    <OperatingExpense formData={formData} disabled={formData.leaseStatusCode === '1' || formData.leaseTypeCode === '1' || formData.leaseTypeCode === '2'} />
                                </table>
                            </td>
                            <td className='unit-table-rates-dates'>
                                <label className='label-title'>{t('headings.rates')}</label>
                                <table className='unit-table half-border' width="100%" role="none">
                                    <Rate formData={formData} handleCurrencyChange={handleCurrencyChange} errors={errors} disabled={formData.leaseStatusCode === '1'} />
                                </table>
                                <br />
                                <table className='unit-table half-border' width="100%" role="none">
                                    <tbody>
                                        <tr>
                                            <td className='label-cell padding-left-10 padding-right-10'>
                                                {t('headings.rent-free-months')}
                                            </td>
                                            <td className="currency-input-td">
                                                <Input type="number" name="freeRentMonth" id="freeRentMonth"
                                                    value={(typeof formData.freeRentMonth === "number") ? formData.freeRentMonth.toString() : null}
                                                    positiveOnly={true} noTimer={true} autoComplete='off'
                                                    title={t('tooltips.rent-free-months-tooltip')}
                                                    disabled={isReadOnly || formData.leaseStatusCode === '1'}
                                                    errorMessage={getFormErrorMessageByName(errors, "freeRentMonth")}
                                                    hideErrorCross={true}
                                                    className={freeRentMonthClassName}
                                                ></Input>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <br />
                                <label className='label-title' title={t('tooltips.step-dates-tooltip')}>{t('headings.step-dates')}</label>
                                <table className='unit-table half-border'role="none">
                                    <StepDate formData={formData} currencySymbol={currencyPerSF}
                                        handleCurrencyChange={handleCurrencyChange} handleDateChange={handleDateChange} errors={errors} disabled={formData.leaseStatusCode === '1'} />
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='btn-grp'>
                    {!isReadOnly && <Button type='submit'>
                        {t("save", { ns: 'common' }).toLocaleUpperCase(i18n.language)}
                    </Button>}
                    {props.showDeleteBtn && (<Button type='danger' onClick={() => props.onCancel()}>
                        {t("delete", { ns: 'common' }).toLocaleUpperCase(i18n.language)}
                    </Button>)}
                </div>
            </form >
        </div>
    );
}
