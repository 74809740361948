import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/root";
import "../PIE.css";
import { ProfileOverviewState } from "../../../redux/reducers/getProfile/getProfileOverviewReducer";
import { SaveDataState } from "../../../redux/reducers/getSaveData/saveDataReducer";
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from "../../../redux/actions/profile/profile";
import NumberOfSitesData from "../../../types/numberOfSites/numberOfSitesData";
import updateProperties from "../../../utils/DataModifier/DataModifier";
import { useTranslation } from "react-i18next";
import Input from "../../../components/common/Input/Input";
import ToolTip from "../../../components/common/ToolTip/ToolTip";
import { SaveResultState } from "../../../redux/reducers/getSaveData/saveResultReducer";
import { ProfileAttributesState } from "../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer";

export interface NumberOfSitesProps {
    readonly?: boolean;
}

function NumberOfSites(props: NumberOfSitesProps): ReactElement {
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
    const dispatch = useDispatch()
    const { t } = useTranslation('number_of_sites')
    const numberOfSiteKeys = ["occupiedLot", "vacantLot", "totalSiteCount", "transientLot", "campgroundSite"]

    const initialNumberOfSitesData: NumberOfSitesData = {
        "occupiedLot": null,
        "vacantLot": null,
        "totalSiteCount": null,
        "transientLot": null,
        "campgroundSite": null,
    }
    const [numberSitesData, setNumberSitesData] = useState<NumberOfSitesData>(initialNumberOfSitesData as NumberOfSitesData)

    useEffect(() => {
        dispatch(resetGetProfileAttributes())
        dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string)))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!profileAttributes.loading && profileAttributes.data) {
            const initData = { ...initialNumberOfSitesData }
            updateProperties(initData, profileAttributes.data)
            setNumberSitesData(initData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileAttributes.data, profileAttributes.loading])

    const handleNumberOfSitesChange = (name: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const currentTarget = e.currentTarget
        const value = currentTarget.value
        let numberValue: number | null = null
        if (typeof value === 'string' && value.trim() !== '') {
            numberValue = parseInt(value)
            currentTarget.value = String(numberValue)
        }
        const newNumberOfSitesData = {...numberSitesData};
        if (name === "occupiedLot") {
            newNumberOfSitesData["totalSiteCount"] = (newNumberOfSitesData.vacantLot ?? 0) + (numberValue ?? 0)
        } else if (name === "vacantLot") {
            newNumberOfSitesData["totalSiteCount"] = (newNumberOfSitesData.occupiedLot ?? 0) + (numberValue ?? 0)
        }
        if (newNumberOfSitesData["totalSiteCount"] === 0) {
            newNumberOfSitesData["totalSiteCount"] = null;
        }
        newNumberOfSitesData[name as keyof typeof numberSitesData] = numberValue;
        setNumberSitesData(newNumberOfSitesData);
    }

    useEffect(() => {
        if (saveData.saving && !props.readonly) {
            const dataOriginal = profileAttributes
            const data = { ...dataOriginal.data, ...numberSitesData } as Record<string, unknown>
            dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string), data))
        }
    }, [dispatch, numberSitesData, profileAttributes, profileOverview.profile.profile_type_code, saveData.saving, props.readonly])

    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string)))
        }
    }, [dispatch, profileOverview.profile.profile_type_code, saveResultState.status])

    return (<>
        <div className="partial-width">
            <table className="table-with-border pie-table number-of-sites-table">
                {props.readonly && <thead className='invisible-header'><tr><th></th></tr></thead>}
                <tbody>
                    {numberOfSiteKeys.map((key) => {
                        if (key === 'totalSiteCount') {
                            return (
                                <tr key={key}>
                                    <td className="label"><ToolTip contents={t(key)}>{t("tooltip." + key)}</ToolTip></td>
                                    <td className="value padding-left-10">{numberSitesData[key]}</td>
                                </tr>)
                        } else {
                            return (
                                <tr key={key}>
                                    <td className="label"><ToolTip contents={t(key)}>{t("tooltip." + key)}</ToolTip></td>
                                    <td className="value">
                                        {props.readonly ? (numberSitesData[key as keyof NumberOfSitesData] !== null ? String(numberSitesData[key as keyof NumberOfSitesData]) : "")
                                        :   <Input className={"padding-left-10"}
                                            value={numberSitesData[key as keyof NumberOfSitesData] !== null ? String(numberSitesData[key as keyof NumberOfSitesData]) : ""}
                                            name={key} id={key} type={"number"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => (handleNumberOfSitesChange(key, e))} />}
                                    </td>
                                </tr>)
                        }
                    })}
                </tbody>
            </table>
        </div>
    </>)
}

export default NumberOfSites